export const AccordionDataBireysel = [
  {
    title: "PARABA nedir ve nasıl çalışır?",
    content:
      "PARABA, Folya Filo'nun kirada olan araçlarına araç bedelinin %1 ile %99 u arasında yatırım yapmanıza imkân sağlayan bir uygulamadır. Üstelik faiz ve komisyon ödemeden.",
  },
  {
    title: "Hangi araçlara yatırım yapabilirim?",
    content:
      "Yatırımcılar, PARABA uygulaması üzerinden fırsatlar bölümdeki Folya Filo'nun sunduğu araçlar arasından dilediği araca yatırım yapabilir.",
  },
  {
    title: "Bir araçta minimum yatırım oranı nedir?",
    content: "Aracın satış bedelinin %1 dir.",
  },
  {
    title: "Birden fazla araçta pay sahibi olabilir miyim?",
    content: "Evet, birden fazla araçta pay sahibi olabilirsiniz.",
  },
  {
    title: "Araç payımı ne zaman satabilirim?",
    content:
      "Araç sözleşme sonunda satıldıktan sonra payınız oranındaki bedel hesabınıza yatırılır.",
  },
  {
    title: "Araç Kiramı ne zaman alabilirim?",
    content:
      "Aracı alırken belirtilen sözleşme başlangıç tarihine göre her ayın o günü hesabınıza yatar.",
  },
  {
    title: "Sözleşme bitiminden sonra almış olduğum pay nasıl değerlendirilir?",
    content:
      "Sözleşme süresi sona erdiğinde, araç ikinci el otomobil açık artırma sitelerinde 3 gün boyunca ihaleye çıkartılır.Sonunda en yüksek bedelle satılır ve sahip olduğunuz araç payı oranında size geri ödenir.",
  },
];

export const AccordionDataKurumsal = [
  {
    title: "Folya Filo İş Ortağım nedir?",
    content:
      "Folya Filo iş ortağım, yatırımcıların bir veya daha fazla aracı satın alarak Folya Filo'ya kiralaması ve bu sayede düzenli gelir elde etmesini sağlayan bir yatırım modelidir.",
  },
  {
    title: "Araç satın alma süreci nasıl işler?",
    content:
      "Araç satın almak isteyen yatırımcılar, Öncelikle bütçelerini Folya filo müşteri danışmanına iletirler. Ardından yetkili danışman bütçe doğrultusunda en avantajlı araç tekliflerini hazırlar ve yatırımcıya sunar. Yatırımcı seçtiği araç doğrultusunda sözleşmeyi imzalar ve ödemesini yapar. Araç alımı gerçekleşir ve sözleşme tarihinden sonraki ilk ay ödeme hesabına yatırılır.",
  },
  {
    title: "Araç yatırımı yapmak için ne kadar bütçeye ihtiyacım var?",
    content:
      "Araç yatırımı için ayırmanız gereken bütçe, gelir beklentinize ve araç kiralama kriterlerine uyan araçların piyasa fiyatlarına göre değişiklik göstermektedir. Bu aşamada, deneyimli sektör uzmanlarımız size yardımcı olacaktır.",
  },
  {
    title: "Araç kiralama yatırımı riskli midir?",
    content:
      "Folya Filo ile yapacağınız sözleşmeyle tarafların sorumlulukları net şekilde belirlenir. Bu sayede sürprizlerle karşılaşmazsınız.",
  },
  {
    title:
      "Kira sözleşmesini süresinden önce feshetmek istersem ne yapmalıyım?",
    content:
      "Erken fesih durumunda sözleşmede yazan cayma bedelini ödemeniz gerekmektedir.",
  },
  {
    title: "Aracımı kimler kiralanıyor?",
    content:
      "Aracınız yalnızca kurumsal firmalara uzun dönem kiralanır. Folya Filo bireysel ve günlük kiralamalar yapmamaktadır.",
  },
  {
    title: "Ofisiniz var mı ? Ziyaret edebilir miyim?",
    content:
      "Randevu alarak ofisimizi ziyaret edebilirsiniz. Sizi ağırlamaktan memnuniyet duyarız.",
  },
  {
    title: "Aracım ilgili her şeyin yolunda olduğunu nasıl öğrenebilirim?",
    content:
      "Folya Filo iş ortağım mobil uygulaması sayesinde aracınızın bilgilerine ulaşabilir ve ödemelerinizi kontrol edebilirsiniz.",
  },
  {
    title: "Kiralama süresi nedir?",
    content:
      "Kiralama süreleri sözleşme ile belirlenir ve minimum 12 ay maksimum 36 ay olabilir.",
  },
  {
    title: "Ne zaman kiralama geliri elde edebilirim?",
    content:
      "Sözleşme tarihinden 1 ay sonra ilk kira tutarı yatırılır. Sözleşme boyunca belirlenen tutar düzenli ödenir.",
  },
  {
    title: "Aracım Kiradayken Masrafları Kim Ödüyor?",
    content:
      "Araç rutin bakımı, lastik masrafları ve kasko poliçesi Folya Filoya aittir. Zorunlu Trafik Sigortası ve MTV ödemeleri araç sahibine aittir.",
  },
  {
    title: "Araç Ruhsatı Kimin Üzerine Olacak",
    content: "Folya filo iş ortağımda ruhsat sahibi siz olursunuz.",
  },
];
