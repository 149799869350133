const serviceData1 = [
    {
        img: "https://via.placeholder.com/800x618",
        icon: "fas fa-arrow-right",
        title: "FINANCE SERVICES",
        content: "Lorem ipsum consectetur adipiscing elit do eiusmod tempor incididunt.",
        link: "/page/our-story"
    },
    {
        img: "https://via.placeholder.com/800x618",
        icon: "fas fa-arrow-right",
        title: "BUSINESS PLANNING",
        content: "Lorem ipsum consectetur adipiscing elit do eiusmod tempor incididunt.",
        link: "/page/our-story"
    },
    {
        img: "https://via.placeholder.com/800x618",
        icon: "fas fa-arrow-right",
        title: "MARKETING STRATEGY",
        content: "Lorem ipsum consectetur adipiscing elit do eiusmod tempor incididunt.",
        link: "/page/our-story"
    }
]

const serviceData2 = [
    {
        img: "/assets/img/services/money.jpg",
        icon: "ti-arrow-right",
        title: "Karlı bir araca yatırım mı yapmak istiyorsunuz?",
        content: "Araç kiralama ile para kazanmak için öncelikle bütçenize karar verin, çevrimiçi hesaplama motorumuzu kullanarak olası gelirinizi öğrenin, Folya Filo olarak sizin için en karlı araçları bulalım.",
        linkTitle: "Bütçe Belirle (çok yakında)",
    },
    {
        img: "/assets/img/services/car.jpg",
        icon: "ti-arrow-right",
        title: "Aracınız var ve kiraya mı vermek istiyorsunuz ?",
        content: "Bir arabanız varsa araç bilgilerinizi girmek ve gelecekteki varlıklarınızı hesaplamak için arama motorumuzu kullanabilirsiniz.Hemen araç bilgilerinizi girin ve işlemlere başlayalım.",
        linkTitle: "ARACIMI KONTROL ET",
        link: "/page/what-we-offer"
    },
    {
        img: "/assets/img/services/support.jpg",
        icon: "ti-arrow-right",
        title: "7/24 Destek almak için bizimle hemen iletişime geçin",
        content: "Tüm yatırım sürecinde yanınızda olarak sizlere danışmanlık hizmetini de sizlere sunmaktayız. Karlı bir yatırımın parçası olmak için hemen bizimle iletişime geçin ve kazanın.",
        linkTitle: "İLETİŞİME GEÇ",
        link: "/page/what-we-offer"
    }
]

const serviceData3 = [
    {
        img: "https://via.placeholder.com/800x560",
        title: "Real time analytics",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
    },
    {
        img: "https://via.placeholder.com/800x560",
        title: "Google advertising",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
    },
    {
        img: "https://via.placeholder.com/800x560",
        title: "Pixel perfect design",
        content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
    }
]

const serviceData4 = [
    {
        img: "https://via.placeholder.com/197x197",
        title: "CONSTRUCTION",
        content: "Lorem elit simply dummy text and typesetting."
    },
    {
        img: "https://via.placeholder.com/197x197",
        title: "ENTERTAINMENT",
        content: "Lorem elit simply dummy text and typesetting."
    },
    {
        img: "https://via.placeholder.com/197x197",
        title: "CROCKERY",
        content: "Lorem elit simply dummy text and typesetting."
    },
    {
        img: "https://via.placeholder.com/197x197",
        title: "AGRICULTURE",
        content: "Lorem elit simply dummy text and typesetting."
    },
    {
        img: "https://via.placeholder.com/197x197",
        title: "RETAILERS",
        content: "Lorem elit simply dummy text and typesetting."
    },
    {
        img: "https://via.placeholder.com/197x197",
        title: "PHARMACEUTICAL",
        content: "Lorem elit simply dummy text and typesetting."
    },
]

const serviceData5 = [
    {
        title: "Developing strategy",
        icon: "feather-arrow-right",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been dummy text.",
        link: "/page/about-me",
        linkTitle: "More Info"
    },
    {
        title: "Blazing performance",
        icon: "feather-arrow-right",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been dummy text.",
        link: "/page/about-me",
        linkTitle: "More Info"
    },
    {
        title: "Customer satisfaction",
        icon: "feather-arrow-right",
        content: "Lorem ipsum is simply dummy text of the printing typesetting lorem ipsum been dummy text.",
        link: "/page/about-me",
        linkTitle: "More Info"
    }
]

const serviceData6 = [
    {
        img: "https://via.placeholder.com/337x337",
        title: "Daily healthy tips",
        content: "Lorem dolor amet consectetur adipiscing eiusmod tempor"
    },
    {
        img: "https://via.placeholder.com/337x337",
        title: "Professional trainers",
        content: "Lorem dolor amet consectetur adipiscing eiusmod tempor"
    },
    {
        img: "https://via.placeholder.com/337x337",
        title: "Physical activity",
        content: "Lorem dolor amet consectetur adipiscing eiusmod tempor"
    },
    {
        img: "https://via.placeholder.com/337x337",
        title: "Natural environment",
        content: "Lorem dolor amet consectetur adipiscing eiusmod tempor"
    },
]

const serviceData7 = [
    {
        img: "https://via.placeholder.com/600x625",
        title: "Cardio",
        content: "Lorem ipsum dolor amet consectetur adipiscing eiusmod tempor.",
        btnName: "Join classes",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/600x625",
        title: "Crossfit",
        content: "Lorem ipsum dolor amet consectetur adipiscing eiusmod tempor.",
        btnName: "Join classes",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/600x625",
        title: "Sculpturing",
        content: "Lorem ipsum dolor amet consectetur adipiscing eiusmod tempor.",
        btnName: "Join classes",
        btnLink: "#"
    },
    {
        img: "https://via.placeholder.com/600x625",
        title: "Cycling",
        content: "Lorem ipsum dolor amet consectetur adipiscing eiusmod tempor.",
        btnName: "Join classes",
        btnLink: "#"
    },
]

export { serviceData1, serviceData2, serviceData3, serviceData4, serviceData5, serviceData6, serviceData7 }