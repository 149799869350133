import ApiService from "./apiMiddleware";

const apiService = new ApiService();

export const makeCalculation = async ({ carDetails }) => {
  const response = await apiService.post(
    `/api/v1/CarDetail/getmonthlyfeebycardetails`,
    carDetails
  );
  return response;
};

export const addContactInfo = async ({ contactDetails }) => {
  const response = await apiService.post(
    `/api/v1/Customer/addcustomer`,
    contactDetails
  );
  return response;
};

export const makeInvestmentCalculation = async (investmentAmount) => {
  const response = await apiService.post(
    `/api/v1/Investment/getinvestmentbyamount?investmentAmount=${investmentAmount}`
  );
  // const response = await apiService.post(`/api/v1/Investment/getinvestmentbyamount`, (investmentAmount));

  return response;
};

export const getCarDetails = async () => {
  const response = await apiService.get(
    `/api/v1/Model/getmodelswithdetailsforwebsite`
  );
  return response;
};

export const sendMail = async (mailDetails) => {
  debugger;

  const response = await apiService.post(`/api/v1/Util/sendmail`, mailDetails);
  console.log(response);
  return response;
};
