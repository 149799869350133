const HeaderData = [
    {
        title: 'Avantajlar',
        link:'#section1'
    },
    {
        title: 'Nasıl Çalışır',
    },
    {
        title: 'Aracımı Kiraya Ver',
    },
    {
        title: 'Araç Kirala',
    },
    {
        title: 'Sıkça Sorulan Sorular',
    },
    {
        title: 'İletişim',
    },
]

export default HeaderData