import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";
import { Analytics } from "@vercel/analytics/react";
import GlobalContext from "./Context/Context";
import HomeStartupPage from "./Pages/Home/Startup/Startup";
import ReactGA from "react-ga4";
import { SpeedInsights } from "@vercel/speed-insights/react";
import PrivacyPage from "./Pages/Privacy";
import DeleteAccountPage from "./Pages/DeleteAccountPage";

const TRACKING_ID = "G-YY9BXNQSC2";
ReactGA.initialize(TRACKING_ID);

ReactGA.send({
  hitType: "pageView",
});

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
          <AnimatePresence exitBeforeEnter>
            <Suspense fallback={<></>}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <HomeStartupPage style={{ "--base-color": "#27ae60" }} />
                  }
                />
                <Route
                  path="/home-startup"
                  element={
                    <HomeStartupPage style={{ "--base-color": "#27ae60" }} />
                  }
                />
                <Route
                  path="/gizlilik-politikasi"
                  element={<PrivacyPage />}
                />
                <Route
                  path="/hesabimi-sil"
                  element={<DeleteAccountPage />}
                />
              </Routes>
            </Suspense>
          </AnimatePresence>
          <Analytics />
          <SpeedInsights />
        </main>
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
