import React, { useState, useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper";
import { LazyMotion, domMax, m } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// Component
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { addContactInfo } from "../../Services/services";

const StartupPageBannerSlider = ({ onContactClick }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [sliderLoaded, setSliderLoaded] = useState(false);
  const swiperRef = useRef(null);

  const swiperData = [
    {
      img: "/assets/banner1.png",
      title: "Araçlarınız Boş Kalmasın!",
      subTitle:
        "Folya Filo ile araçlarınızı kiraya verin, kazancınızı maksimize edin.",
    },
    {
      img: "/assets/banner2.png",
      title: "Araçlarınız Boş Kalmasın!",
      subTitle:
        "Folya Filo ile araçlarınızı kiraya verin, kazancınızı maksimize edin.",
    },
    {
      img: "/assets/banner3.png",
      title: "Araçlarınız Boş Kalmasın!",
      subTitle:
        "Folya Filo ile araçlarınızı kiraya verin, kazancınızı maksimize edin.",
    },
  ];

  // Slider'ın sadece bir kez yüklenmesini sağlamak için useEffect kullanıyoruz
  useEffect(() => {
    if (!sliderLoaded) {
      setSliderLoaded(true);
    }
  }, [sliderLoaded]);

  return (
    <section className="bg-darkgray home-startup-swiper">
      <LazyMotion strict features={domMax}>
        {sliderLoaded && (
          <Swiper
            ref={swiperRef}
            effect="fade"
            slidesPerView={1}
            spaceBetween={0}
            loop={true}
            navigation={false}
            pagination={true}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            fadeEffect={{ crossFade: true }}
            modules={[Pagination, Navigation, EffectFade, Autoplay]}
            onSlideChange={({ realIndex }) => setActiveSlide(realIndex)}
            className="startup swiper-navigation-04 swiper-navigation-light swiper-pagination-03 swiper-pagination-light swiper-pagination-medium hide-nav-mobile"
          >
            {swiperData.map((item, i) => (
              <SwiperSlide key={i} className="overflow-hidden">
                <m.div
                  initial={{ scale: 1.2 }}
                  animate={{ scale: activeSlide === i ? 1 : 1.2 }}
                  transition={{ duration: 1.7, ease: "easeInOut" }}
                  style={{ backgroundImage: `url(${item.img})` }}
                  className="overflow-hidden absolute h-full w-full top-0 left-0 cover-background 
                    md:h-[40vh] sm:h-[30vh] xs:h-[25vh]"
                >
                  <img
                    src={item.img}
                    alt=""
                    className="absolute h-full w-full object-cover object-center"
                    loading="lazy"
                  />
                </m.div>
                <Container className="text-center">
                  <Row
                    className="full-screen items-center justify-center 
                    md:h-[40vh] sm:h-[30vh] xs:h-[25vh]"
                  >
                    <Col
                      xs={12}
                      lg={7}
                      md={10}
                      className="justify-center items-center my-0 mx-auto relative flex flex-col"
                    >
                      {/* Content buraya gelecek */}
                    </Col>
                  </Row>
                </Container>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </LazyMotion>
    </section>
  );
};

StartupPageBannerSlider.propTypes = {
  onContactClick: PropTypes.func.isRequired,
};

export default StartupPageBannerSlider;
