import React from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap'
import FooterMenu, { Footer } from "../../Components/Footers/Footer";
import { Link } from "react-router-dom";
import { Link as ScrollTo } from "react-scroll";
// Components
import { Header, HeaderCart, HeaderLanguage, HeaderNav, Menu, SearchBar } from '../../Components/Header/Header'
import Lists from "../../Components/Lists/Lists"

const PrivacyPage = (props) => {
    return (
        <div style={props.style}>
            {/* Header Start */}
            {/* Header End */}
            {/* Section Start */}
            <section className="bg-lightgray py-[40px] sm:py-[30px]">
                <Container>
                    <Row className="xs:text-center">
                        <Col xl={8} lg={6} className="flex md:justify-center md:mb-[15px] sm:block sm:text-center">
                            <h1 className="text-lg text-darkgray font-medium mb-0 font-serif inline-block">
                                <a href="https://www.folyafilo.com" className="underline">Anasayfa</a> - Gizlilik Sözleşmesi
                            </h1>                        </Col>
                        <Col xl={4} lg={6} className="breadcrumb justify-end px-[15px] text-sm font-serif m-0 md:justify-center">
                            <ul>
                                <li>Gizlilik Sözleşmesi</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Section End */}
            {/* Section Start */}
            <section className="py-[160px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]">
                <Container>
                    <Row className="items-center justify-center">
                        <Col lg={9} md={12} className="col-12">
                            <h1 className="font-serif text-darkgray font-medium -tracking-[1px]">GİZLİLİK SÖZLEŞMESİ</h1>

                            <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Toplanan Veriler</h6>
                            <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Şirket olarak, aşağıdaki kişisel verileri toplayabiliriz:</p>
                            <ul>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Kimlik Bilgileri: Ad, soyad, doğum tarihi, T.C. kimlik numarası gibi kimlik bilgileri.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">İletişim Bilgileri: Adres, telefon numarası, e-posta adresi.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Araç Kiralama Bilgileri: Kiralanan araç bilgileri, kiralama süresi, ödeme bilgileri.</li>
                            </ul>
                            <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Kişisel Verilerin Kullanım Amaçları</h6>
                            <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Topladığımız kişisel veriler, aşağıdaki amaçlar doğrultusunda işlenebilir:</p>
                            <ul>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Hizmet Sunumu: Araç kiralama hizmetlerinin sunulması ve yönetilmesi.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Müşteri İlişkileri: Müşteri ilişkilerinin yönetimi ve müşteri memnuniyetinin sağlanması.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Sözleşme Yönetimi: Sözleşme süreçlerinin yürütülmesi.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Finansal İşlemler: Finansal işlemlerin gerçekleştirilmesi.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Yasal Yükümlülükler: Yasal yükümlülüklerin yerine getirilmesi.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Hizmet Geliştirme: Hizmetlerimizin geliştirilmesi ve kişiselleştirilmesi.</li>
                            </ul>
                            <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Kişisel Verilerin Korunması</h6>
                            <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Kişisel verilerinizi korumak için uygun teknik ve idari güvenlik önlemleri almaktayız. Bu önlemler, kişisel verilerinizin yetkisiz erişim, kayıp, yanlış kullanım, ifşa edilme veya değiştirilme risklerine karşı korunmasını sağlamaktadır.</p>
                            <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Kişisel Verilerin Paylaşılması</h6>
                            <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Kişisel verileriniz, aşağıdaki durumlar dışında üçüncü kişilerle paylaşılmayacaktır:</p>
                            <ul>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Yasal Yükümlülükler: Yasal yükümlülüklerin yerine getirilmesi.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">İş Ortakları: Sözleşmesel gereklilikler çerçevesinde iş ortaklarımızla paylaşılması.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Hizmet Sağlayıcılar: Hizmet sağlayıcılarımız ve iş ortaklarımızla, hizmetlerin sunulabilmesi için gerekli olduğu durumlarda paylaşılması.</li>
                            </ul>
                            <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Çerezler ve Benzeri Teknolojiler</h6>
                            <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Web sitemiz ve mobil uygulamamız, kullanıcı deneyimini iyileştirmek ve analiz yapmak amacıyla çerezler ve benzeri teknolojiler kullanabilir. Çerezler, tarayıcınıza veya cihazınıza kaydedilen küçük veri dosyalarıdır. Çerezleri nasıl kullandığımız ve nasıl yönetebileceğiniz hakkında daha fazla bilgi için Çerez Politikamıza başvurabilirsiniz.</p>
                            <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Haklarınız</h6>
                            <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Kişisel verilerinizle ilgili olarak, aşağıdaki haklara sahipsiniz:</p>
                            <ul>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Kişisel verilerinizin işlenip işlenmediğini öğrenme.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">İşlenmişse buna ilişkin bilgi talep etme.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Kişisel verilerinizin silinmesini veya yok edilmesini isteme.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Kişisel verilerinizin düzeltilmesi, silinmesi veya yok edilmesi işlemlerinin, kişisel verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle, aleyhinize bir sonucun ortaya çıkmasına itiraz etme.</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme.</li>
                            </ul>
                            <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">İletişim</h6>
                            <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Kişisel verilerinizle ilgili sorularınız veya talepleriniz için aşağıdaki iletişim bilgilerini kullanarak bizimle iletişime geçebilirsiniz:</p>
                            <ul>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Folya Filo Kiralama Anonim Şirketi</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Adres: Yalı Mah. Topselvi Cad. Mai Residence B Blok 100B/81 Kartal / İstanbul</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">E-posta: info@folyafilo.com</li>
                                <li className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Telefon: 0850 762 59 09</li>
                            </ul>
                            <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Değişiklikler</h6>
                            <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Bu Gizlilik Politikası, zaman zaman güncellenebilir. Herhangi bir değişiklik yapıldığında, güncellenmiş politika web sitemizde yayınlanacak ve gerekli durumlarda sizlere bildirilecektir.</p>

                            <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Kişisel Veri Saklama Süresi</h6>
                            <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Şirketimiz, KVKK uyarınca işlediği Kişisel Verileri yalnızca ilgili mevzuatta öngörülen veya mevzuatta bir süre öngörülmemiş ise kişisel veri işleme amacının gerektirdiği süre kadar muhafaza eder. Tutulan veriler verinin işlenmesini gerektiren sebepler sona erdikten sonra silinir, yok edilir veya anonim hale getirilir.</p>

                            <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Otomatik Olarak Toplanan Kişisel Olmayan Veriler</h6>
                            <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Müşteri, Şirketimizin web sitesine eriştiği zaman, kişisel olmayan verilerinin (örneğin, kullanılan İnternet tarayıcısı ve işletim sisteminin türü, geldiğiniz Web sitesinin alan adı, ziyaret sayısı, IP adresi, IMEI veya IMSI numaraları gibi cihaz tanımlayıcıları veya diğer tanımlayıcılar, sitede harcanan ortalama süre, görüntülenen sayfalar, bir metin mesajı ile gönderilen veriler.) otomatik olarak toplanabileceğini (yani kayıt ile değil) kabul ve beyan etmiş sayılır. Müşteri bu verilerin, Şirketimizin web sitelerinin çekiciliğini izlenebilmesi ve performansını ya da içeriğini geliştirilebilmesi amacıyla kullanabileceğini ve iş ortakları ile paylaşabileceğini kabul ve beyan etmiş sayılır.</p>

                            <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Diğer Kaynaklardan Elde Edilen Veriler</h6>
                            <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">Müşteri, Şirketimiz tarafından diğer kaynaklardan elde edilen verilerin (ödeme hizmetleri sağlayıcılarından elde edilen veriler, kamuya açık bilgiler, sosyal medyadan elde edilmiş olan veriler) otomatik olarak toplanabileceğini kabul ve beyan etmiş sayılır.</p>

                            <h6 className="font-serif text-darkgray font-medium -tracking-[1px]">Uygulanacak Hukuk, Yetkili Mahkeme ve İcra Daireleri</h6>
                            <p className="mb-[25px] md:mb-[20px] sm:mb-[15px]">İşbu Gizlilik Sözleşmesi, Türkiye Cumhuriyeti kanunlarına tabidir. Gizlilik, Kişisel Verilerin Korunması ve Çerez Politikası’nın uygulanmasından doğabilecek her türlü uyuşmazlığın çözümünde İstanbul Anadolu Mahkeme ve İcra Daireleri yetkilidir.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Section End */}
            {/* Footer start */}
            <Footer theme="dark" className="text-[#828282] bg-darkgray">
                <div className="pt-[6%] lg:pt-[8%] footer-menu xs:py-[50px]">
                    <Container>
                        <Row className="justify-between md:text-start gap-y-[60px] w-full">
                            <Col
                                lg={{ span: 3, order: 0 }}
                                md={6}
                                sm={{ span: 6, order: 1 }}
                            >
                                <Link
                                    aria-label="link"
                                    to="/"
                                    className="mb-[30px] inline-block xs:mb-[20px]"
                                >
                                    <img
                                        src="/assets/img/logo/logo.png"
                                        alt="logo"
                                        width="35"
                                        height="34"
                                        className="w-auto"
                                    />
                                </Link>
                                <p className="subtitle font-serif font-light ml-8 w-[85%] lg:w-full md:w-[70%] sm:w-full text-[20px] leading-[36px] -tracking-[0.5px] xs:w-[90%]">
                                    Folya Filo ile Yatırımlarınız Yollarda Değer Kazansın!
                                </p>
                            </Col>
                            <div class="footer-menu justify-between  mt-2 col-lg-auto col-sm-6 order-lg-0 order-sm-3">
                                <span class="mb-[20px] block font-medium font-serif xs:!mb-[10px] uppercase traking-[2px]">
                                    SİTE Haritası
                                </span>
                                <ul>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="avantajlar"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            Avantajlar
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="nasil-calisir"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            Araç Yatırımı
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="aracimi-kiraya-ver"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            Aracımı kiraya ver
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="arac-kirala"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            Kurumsal Araç Kiralama{" "}
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="sikca-sorulan-sorular"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            Sıkça Sorulan Sorular
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="hakkimizda"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            Hakkımızda
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="iletisim"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            İletişim
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <Link
                                            to="/gizlilik-politikasi" // Yeni sayfa yolu
                                            className="inner-link nav-link"
                                        >
                                            Gizlilik Sözleşmesi
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer-menu justify-between mt-8 mt-2 col-lg-auto col-sm-6 order-lg-0 order-sm-3">
                                <ul>
                                    <span className="mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]">
                                        İLETİŞİM
                                    </span>
                                    <div>
                                        <i className="feather-navigation text-sm mr-[10px] text-themecolor"></i>
                                        <a href="https://www.google.com.tr/maps/dir/40.9141248,29.2159488/Folya+Filo+Kiralama+A.%C5%9E+Kartal/@40.9069169,29.2030521,15z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14cac5158cf45df5:0x17836df1ab30e15!2m2!1d29.2186121!2d40.8997099?hl=tr-TR&entry=ttu">
                                            Yalı Mah. Topselvi Cad. B Blok Mai Rezidans NO : 100B/81
                                            Kartal İstanbul
                                        </a>
                                    </div>
                                    <div>
                                        <i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>
                                        <a href="tel:+908507625909">0850 762 59 09</a>
                                    </div>
                                    <div>
                                        <i className="feather-mail text-sm mr-[10px] text-themecolor"></i>
                                        <a aria-label="mail" href="mailTo:info@folyafilo.com">
                                            info@folyafilo.com
                                        </a>
                                    </div>
                                </ul>
                            </div>
                        </Row>
                    </Container>
                </div>
                <div className="py-[6%] xs:pt-0 xs:p-0 footer-details">
                    <Container>
                        <Row>
                            <Col
                                sm={{ span: 12, order: 2 }}
                                md={{ span: 6, order: 1 }}
                                xs={{ order: 2 }}
                                className="text-start flex sm:justify-center md:text-end md:mb-4 xs:justify-start"
                            >
                                <p>
                                    &copy; {new Date().getFullYear()} Folya Filo Tüm Hakları
                                    Saklıdır.{" "}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Footer>
            {/* Footer end */}
        </div>
    )
}

export default PrivacyPage
