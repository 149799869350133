import React, { useState } from 'react';

// Libraries
import { Col, Container, Row, Button, Form, Modal } from 'react-bootstrap';
import FooterMenu, { Footer } from "../../Components/Footers/Footer";
import { Link } from "react-router-dom";
import { Link as ScrollTo } from "react-scroll";
const DeleteAccountPage = (props) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleDeleteAccount = () => {
        // API çağrısı yerine fake bir popup gösteriyoruz
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <div style={props.style}>
            {/* Header Start */}
            {/* Header End */}
            {/* Section Start */}
            <section className="bg-lightgray py-[40px] sm:py-[30px]">
                <Container>
                    <Row className="xs:text-center">
                        <Col xl={8} lg={6} className="flex md:justify-center md:mb-[15px] sm:block sm:text-center">
                            <h1 className="text-lg text-darkgray font-medium mb-0 font-serif inline-block">
                                <a href="https://www.folyafilo.com" className="underline">Anasayfa</a> - Hesap Silme
                            </h1>
                        </Col>
                        <Col xl={4} lg={6} className="breadcrumb justify-end px-[15px] text-sm font-serif m-0 md:justify-center">
                            <ul>
                                <li>Hesap Silme</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Section End */}

            {/* Section Start */}
            <section className="py-[100px] lg:py-[80px] md:py-[60px] sm:py-[50px] xs:py-[40px]">
                <Container>
                    <Row className="items-center justify-center">
                        <Col lg={6} md={12} className="col-12">
                            <h2 className="font-serif text-darkgray font-medium mb-4">Hesabınızı Silmek İçin Telefon Numaranızı Girin</h2>
                            <p className="mb-4">Lütfen aşağıdaki alana telefon numaranızı girin ve hesabınızı silmek için "Hesabımı Sil" butonuna tıklayın.</p>
                            <Form>
                                <Form.Group controlId="formPhoneNumber" className="mb-3">
                                    <Form.Label>Telefon Numarası</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Telefon numaranızı girin"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Button
                                    onClick={handleDeleteAccount}
                                    style={{
                                        backgroundColor: 'red',  // Butonun normal arka plan rengini kırmızı yap
                                        color: 'white',          // Metin rengini beyaz yaparak kontrast sağla
                                        padding: '10px 20px',    // Butonun etrafındaki boşluğu ayarla
                                        fontSize: '16px',        // Metin boyutunu artır
                                        fontWeight: 'bold',      // Metni kalın yap
                                        border: '2px solid darkred', // Kenarlık ekle
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Hafif gölge ekle
                                        borderRadius: '5px',     // Yuvarlak köşeler ekle
                                        transition: 'background-color 0.3s ease' // Geçiş efekti ekle
                                    }}
                                    onMouseEnter={(e) => e.target.style.backgroundColor = '#b30000'}  // Hover sırasında arka plan rengini değiştir
                                    onMouseLeave={(e) => e.target.style.backgroundColor = 'red'}      // Hover sonrası eski rengi geri getir
                                >
                                    Hesabımı Sil
                                </Button>

                            </Form>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Section End */}

            {/* Modal for confirmation */}
            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Hesap Silme İsteği</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Silme isteğiniz işleme alınmıştır. 3 iş günü içerisinde hesabınız silinecektir.
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={handleCloseModal}
                        style={{
                            backgroundColor: 'green',  // Butonun arka plan rengini yeşil yap
                            color: 'white',            // Metin rengini beyaz yap
                            padding: '10px 20px',      // Butonun boyutunu ayarla
                            fontSize: '16px',          // Metin boyutunu artır
                            fontWeight: 'bold',        // Kalın font kullan
                            border: '2px solid darkgreen', // Kenarlık ekle
                            borderRadius: '5px',       // Yuvarlak köşeler ekle
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Hafif gölge ekle
                            transition: 'background-color 0.3s ease' // Hover efektini yumuşat
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = '#006400'}  // Hover sırasında daha koyu yeşil yap
                        onMouseLeave={(e) => e.target.style.backgroundColor = 'green'}    // Hover sonrası orijinal yeşil renge geri dön
                    >
                        Tamam
                    </Button>

                </Modal.Footer>
            </Modal>

            {/* Footer start */}
            <Footer theme="dark" className="text-[#828282] bg-darkgray">
                <div className="pt-[6%] lg:pt-[8%] footer-menu xs:py-[50px]">
                    <Container>
                        <Row className="justify-between md:text-start gap-y-[60px] w-full">
                            <Col
                                lg={{ span: 3, order: 0 }}
                                md={6}
                                sm={{ span: 6, order: 1 }}
                            >
                                <Link
                                    aria-label="link"
                                    to="/"
                                    className="mb-[30px] inline-block xs:mb-[20px]"
                                >
                                    <img
                                        src="/assets/img/logo/logo.png"
                                        alt="logo"
                                        width="35"
                                        height="34"
                                        className="w-auto"
                                    />
                                </Link>
                                <p className="subtitle font-serif font-light ml-8 w-[85%] lg:w-full md:w-[70%] sm:w-full text-[20px] leading-[36px] -tracking-[0.5px] xs:w-[90%]">
                                    Folya Filo ile Yatırımlarınız Yollarda Değer Kazansın!
                                </p>
                            </Col>
                            <div class="footer-menu justify-between  mt-2 col-lg-auto col-sm-6 order-lg-0 order-sm-3">
                                <span class="mb-[20px] block font-medium font-serif xs:!mb-[10px] uppercase traking-[2px]">
                                    SİTE Haritası
                                </span>
                                <ul>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="avantajlar"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            Avantajlar
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="nasil-calisir"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            Araç Yatırımı
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="aracimi-kiraya-ver"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            Aracımı kiraya ver
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="arac-kirala"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            Kurumsal Araç Kiralama{" "}
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="sikca-sorulan-sorular"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            Sıkça Sorulan Sorular
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="hakkimizda"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            Hakkımızda
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <ScrollTo
                                            to="iletisim"
                                            offset={80}
                                            delay={0}
                                            spy={true}
                                            smooth={true}
                                            duration={800}
                                            className="inner-link nav-link"
                                        >
                                            İletişim
                                        </ScrollTo>
                                    </li>
                                    <li className="mb-[7px] last:mb-0 cursor-pointer">
                                        <Link
                                            to="/gizlilik-politikasi" // Yeni sayfa yolu
                                            className="inner-link nav-link"
                                        >
                                            Gizlilik Sözleşmesi
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div class="footer-menu justify-between mt-8 mt-2 col-lg-auto col-sm-6 order-lg-0 order-sm-3">
                                <ul>
                                    <span className="mb-[20px] font-serif block font-medium text-themecolor xs:mb-[10px]">
                                        İLETİŞİM
                                    </span>
                                    <div>
                                        <i className="feather-navigation text-sm mr-[10px] text-themecolor"></i>
                                        <a href="https://www.google.com.tr/maps/dir/40.9141248,29.2159488/Folya+Filo+Kiralama+A.%C5%9E+Kartal/@40.9069169,29.2030521,15z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x14cac5158cf45df5:0x17836df1ab30e15!2m2!1d29.2186121!2d40.8997099?hl=tr-TR&entry=ttu">
                                            Yalı Mah. Topselvi Cad. B Blok Mai Rezidans NO : 100B/81
                                            Kartal İstanbul
                                        </a>
                                    </div>
                                    <div>
                                        <i className="feather-phone-call text-sm mr-[10px] text-themecolor"></i>
                                        <a href="tel:+908507625909">0850 762 59 09</a>
                                    </div>
                                    <div>
                                        <i className="feather-mail text-sm mr-[10px] text-themecolor"></i>
                                        <a aria-label="mail" href="mailTo:info@folyafilo.com">
                                            info@folyafilo.com
                                        </a>
                                    </div>
                                </ul>
                            </div>
                        </Row>
                    </Container>
                </div>
                <div className="py-[6%] xs:pt-0 xs:p-0 footer-details">
                    <Container>
                        <Row>
                            <Col
                                sm={{ span: 12, order: 2 }}
                                md={{ span: 6, order: 1 }}
                                xs={{ order: 2 }}
                                className="text-start flex sm:justify-center md:text-end md:mb-4 xs:justify-start"
                            >
                                <p>
                                    &copy; {new Date().getFullYear()} Folya Filo Tüm Hakları
                                    Saklıdır.{" "}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Footer>
            {/* Footer end */}
        </div>
    );
};

export default DeleteAccountPage;
