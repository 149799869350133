import React, { memo } from "react";

// Libraries
import { PropTypes } from "prop-types";

const GoogleMap = (props) => {
  return (
    <iframe
      title="Google Map"
      className={props.className}
      src={props.location}
      width="600"
      height="540"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

GoogleMap.defaultProps = {
  location:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3015.7312453233685!2d29.2186121!3d40.8997099!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac5158cf45df5%3A0x17836df1ab30e15!2sFolya%20Filo%20Kiralama%20A.%C5%9E!5e0!3m2!1sen!2str!4v1732902374054!5m2!1sen!2str",
};

GoogleMap.propTypes = {
  location: PropTypes.string,
  className: PropTypes.string,
};

export default memo(GoogleMap);
