import React, { useRef, useState, useMemo, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

// Libraries
import { Link } from "react-router-dom";
import { Link as ScrollTo } from "react-scroll";

import { Col, Container, Navbar, Row } from "react-bootstrap";
import { m } from "framer-motion";
import TextField from "@mui/material/TextField";
import {
  Button as MUIButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chart from "react-apexcharts";
// Functions
import {
  fadeIn,
  fadeInLeft,
  fadeInRight,
} from "../../../Functions/GlobalAnimations";

// Components
import FooterMenu, { Footer } from "../../../Components/Footers/Footer";

// Data
import FooterData from "../../../Components/Footers/FooterData";

import Accordion from "../../../Components/Accordion/Accordion";
import {
  AccordionDataBireysel,
  AccordionDataKurumsal,
} from "../../../Components/Accordion/AccordionData";
import GoogleMap from "../../../Components/GoogleMap/GoogleMap";

import IconWithText from "../../../Components/IconWithText/IconWithText";
import Header from "../../../Components/Header/Header";
import { HeaderNav } from "../../../Components/Header/Header";
import ProcessStep from "../../../Components/ProcessStep/ProcessStep";
import Overlap from "../../../Components/Overlap/Overlap";
import SideButtons from "../../../Components/SideButtons";
import { TiltBox } from "../../../Components/FancyText/FancyText";
import StartupPageBannerSlider from "./StartupBanner";
import {
  getCarDetails,
  makeCalculation,
  makeInvestmentCalculation,
  sendMail,
  addContactInfo,
} from "../../Services/services";
import {
  IconWithTextData_02,
  IconWithTextData_03,
} from "../../../Components/IconWithText/IconWithTextData";
import { TestimonialsCarouselData2 } from "../../../Components/TestimonialCarousel/TestimonialsCarouselData";
import TestimonialsCarousel02 from "../../../Components/TestimonialCarousel/TestimonialsCarousel02";
import InViewPort from "../../../Components/InViewPort";
import Services from "../../../Components/Services/Services";
import { Tab, Tabs } from "react-bootstrap";

const iconWithTextData = [
  {
    icon: "line-icon-Cursor-Click2 text-[#19876A]",
    title: "Şeffaf ve güvenilir ortaklık",
    content: "",
  },
  {
    icon: "line-icon-Bakelite text-[#19876A]",
    title: "Düzenli gelir elde edin",
    content: "",
  },
  {
    icon: "line-icon-Boy text-[#19876A]",
    title: "Enflasyondan etkilenmeyin",
    content: "",
  },
];

const ProcessStepData = [
  {
    title: "Bütçe Belirleyin",
    content:
      "Size özel araç portföyümüzü sunabilmemiz için yatırım bütçenizi belirleyin.",
    icon: "line-icon-Money-2", // Para/bütçe ikonu
  },
  {
    title: "Sizin için karlı bir araç bulalım",
    content:
      "Uzman ekibimiz bütçenize ve beklentilerinize en uygun aracı belirler.",
    icon: "line-icon-Car-2", // Araba ikonu
  },
  {
    title: "Aracın ödemesini yapın",
    content: "Güvenli ödeme seçenekleriyle aracınızın ödemesini tamamlayın.",
    icon: "line-icon-Credit-Card2", // Kredi kartı ikonu
  },
  {
    title: "Kira geliri için fiyat teklifi paylaşalım",
    content:
      "Aracınızın piyasa değeri ve talep durumuna göre en uygun kira teklifini sunalım.",
    icon: "line-icon-Money-Bag", // Para çantası ikonu
  },
  {
    title: "Sözleşme yapalım",
    content: "Karşılıklı güvence altına alınan sözleşme ile süreç başlasın.",
    icon: "line-icon-File-Edit", // Dosya/sözleşme ikonu
  },
  {
    title: "Kira gelirinizin tadını çıkarın",
    content: "Düzenli kira geliriniz her ay hesabınıza yatsın.",
    icon: "line-icon-Money", // Para ikonu
  },
];

const Footer_Data = [
  FooterData[0],
  FooterData[1],
  FooterData[4],
  FooterData[3],
];

// Sol ve Sağ taraftaki kartlar için ortak stil ve veri
const featureCards = {
  left: [
    {
      icon: "fas fa-chart-line",
      title: "Fırsatlar ve Detaylı Bilgi Sayfası",
      description:
        "Araç fiyatlarını ve aylık kira getirilerini karşılaştırarak, size en uygun yatırım fırsatını kolayca seçebilirsiniz.",
      gradient: "from-emerald-500/20 to-teal-500/20",
    },
    {
      icon: "fas fa-car",
      title: "Detaylı Araç Bilgisi",
      description:
        "Seçtiğiniz aracın tüm yatırım detaylarını, tramer, boya ve sözleşme bilgilerini inceleyin.",
      gradient: "from-green-500/20 to-emerald-500/20",
    },
    {
      icon: "fas fa-calculator",
      title: "Yatırım Hesaplama",
      description:
        '"Yatırım Yap" butonuna tıklayarak belirlediğiniz tutar üzerinden aylık kira getirilerinizi hesaplayarak, yatırımınızın gelecekteki kazancını önceden planlayın.',
      gradient: "from-teal-500/20 to-green-500/20",
    },
  ],
  right: [
    {
      icon: "fas fa-bolt",
      title: "Hızlı ve Güvenli Sipariş",
      description:
        "Yatırımın getirisini hesapladıktan sonra, bilgilerinizi doldurup iban numaranızı girerek sipariş oluşturun.",
      gradient: "from-emerald-500/20 to-teal-500/20",
    },
    {
      icon: "fas fa-wallet",
      title: "Ödeme ve Hesap Yönetimi",
      description:
        "Yatırım tutarınızı sipariş numaranızla birlikte Folya Filo hesabına havale yaparak yatırımınızı başlatın. 'Hesabım' sekmesinden kira tutarlarınızı biriktirebilir veya çekim talebi oluşturarak banka hesabınıza aktarabilirsiniz.",
      gradient: "from-green-500/20 to-emerald-500/20",
    },
    {
      icon: "fas fa-chart-bar",
      title: "Yatırım ve Sipariş Takibi",
      description:
        "Yatırımlarınızı ve sipariş detaylarını 'Yatırımlarım' sayfasından takip edebilirsiniz.",
      gradient: "from-teal-500/20 to-green-500/20",
    },
  ],
};

const FeatureCard = ({ feature, isRight }) => (
  <m.div
    initial={{ opacity: 0, x: isRight ? 50 : -50 }}
    whileInView={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.5 }}
    viewport={{ once: true }}
    className="group relative"
  >
    <div
      className={`
      absolute inset-0 bg-gradient-to-r ${feature.gradient} rounded-2xl
      transform transition-all duration-300 group-hover:scale-105 blur-lg opacity-50
    `}
    ></div>
    <div
      className={`
      relative bg-white/80 backdrop-blur-sm rounded-2xl p-6
      hover:bg-white/95 transition-all duration-300
      border border-[#19876A]/10 hover:border-[#19876A]/30
      transform hover:-translate-y-1 hover:shadow-xl
    `}
    >
      <div className="flex items-start space-x-4">
        {/* İkon Container */}
        <div
          className={`
          w-14 h-14 rounded-xl bg-gradient-to-br from-[#19876A] to-[#1A9A6A]
          flex items-center justify-center transform transition-all duration-300
          group-hover:scale-110 shadow-lg group-hover:shadow-xl
        `}
        >
          <i
            className={`${feature.icon} text-white text-xl transform transition-all duration-300 group-hover:rotate-12`}
          ></i>
        </div>

        {/* İçerik */}
        <div className="flex-1 space-y-2">
          <h3
            className={`
            font-serif text-lg font-semibold text-darkgray
            transform transition-all duration-300
            group-hover:text-[#19876A] group-hover:translate-x-1
          `}
          >
            {feature.title}
          </h3>
          <p className="text-gray-600 leading-relaxed text-sm transition-all duration-300 group-hover:text-gray-700">
            {feature.description}
          </p>
        </div>
      </div>

      {/* Dekoratif Çizgi */}
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-0 h-0.5 bg-gradient-to-r from-[#19876A] to-[#1A9A6A] transition-all duration-300 group-hover:w-full"></div>
    </div>
  </m.div>
);

const HomeStartupPage = (props) => {
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedClass, setSelectedClass] = useState();
  const [selectedModel, setSelectedModel] = useState();
  const [selectedGearType, setSelectedGearType] = useState();
  const [selectedFuelType, setSelectedFuelType] = useState();
  const [selectedBodyType, setSelectedBodyType] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedKilometer, setSelectedKilometer] = useState();
  const [carDetails, setCarDetails] = useState([]);
  const [chartDetail, setChartDetail] = useState([]);
  const [investmentAmount, setInvestmentAmount] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [taxNumber, setTaxNumber] = useState("");
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [dialogConfirmationOpen, setDialogConfirmationOpen] =
    React.useState(false);

  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    email: "",
    il: "",
    ilce: "",
    vergiil: "",
    vergiilce: "",
    kiralamasuresi: "",
    kiralanacakaracsayisi: "",
    kiralanacakaracmarka: "",
    kiralanacakaracmodel: "",
    vergino: "",
    aracyillikkm: "",
    firmawebsitesi: "",
    not: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    surname: "",
    phoneNumber: "",
    email: "",
    il: "",
    ilce: "",
    vergiil: "",
    vergiilce: "",
    kiralamasuresi: "",
    kiralanacakaracsayisi: "",
    kiralanacakaracmarka: "",
    kiralanacakaracmodel: "",
    vergino: "",
    aracyillikkm: "",
  });

  const [selectedName, setSelectedName] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [contactDialogConfirmationOpen, setContactDialogConfirmationOpen] =
    useState(false);
  const [isContactButtonDisabled, setIsContactButtonDisabled] = useState(true);

  // State ekleyelim (component başında diğer state'lerin yanına)
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    const allFieldsFilled =
      selectedName && selectedPhoneNumber && selectedEmail;
    setIsContactButtonDisabled(!allFieldsFilled);
  }, [selectedName, selectedPhoneNumber, selectedEmail]);

  const handleContactDialogOpen = () => {
    console.log("Dialog opening..."); // Debug için
    setContactDialogOpen(true);
  };

  const handleContactDialogClose = () => setContactDialogOpen(false);

  const onContactRequestClose = () => {
    setContactDialogOpen(false);
    setContactDialogConfirmationOpen(true);
  };

  const handleContactConfirmationClose = () =>
    setContactDialogConfirmationOpen(false);

  const handleContactRequest = async () => {
    onContactRequestClose();

    const contactDetails = {
      name: selectedName,
      email: selectedEmail,
      phoneNumber: selectedPhoneNumber,
      isCommunicated: true,
    };

    await addContactInfo({ contactDetails });
  };

  const handleChangex = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Error mesajını temizle
    if (value.trim() !== "") {
      setErrorMessages((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    // Zorunlu olan alanların listesi
    const requiredFields = [
      "name",
      "surname",
      "phoneNumber",
      "email",
      "il",
      "ilce",
      "vergiil",
      "vergiilce",
      "kiralamasuresi",
      "kiralanacakaracsayisi",
      "kiralanacakaracmarka",
      "kiralanacakaracmodel",
      "vergino",
      "aracyillikkm",
    ];

    // Yalnızca zorunlu alanları kontrol et
    requiredFields.forEach((key) => {
      if (!formData[key].trim()) {
        isValid = false;
        errors[key] = "Zorunlu alanları lütfen doldurunuz.";
      }
    });

    setErrorMessages(errors);
    return isValid;
  };

  const handleSubmit = async () => {
    debugger;
    if (validateForm()) {
      const res = await sendMail(formData);
      console.log("Form başarıyla gönderildi");
      setDialogConfirmationOpen(true);
    } else {
      console.log("Formda eksik alanlar var");
    }
  };

  const handleTaxNumberChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, "").slice(0, 9); // Sadece sayıları kabul et ve maksimum uzunluğu 9 karakterle sınırla
    setTaxNumber(value);
    if (error && value.length === 9) {
      // Eğer hata mesajı gösteriliyor ve uzunluk 9 karaktere ulaştıysa, hatayı kaldır
      setError(false);
      setHelperText("");
    }
  };

  const itemsPerPage = 5;
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
  };

  // Yatırım grafik detayları
  const investmenetChartDetails = {
    options: {
      colors: ["#19876A"],
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false, // Toolbar'ı kaldır
        },
        fontFamily: "inherit",
        background: "transparent",
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.2,
          stops: [0, 90, 100],
          colorStops: [
            {
              offset: 0,
              color: "#19876A",
              opacity: 0.4,
            },
            {
              offset: 100,
              color: "#19876A",
              opacity: 0.1,
            },
          ],
        },
      },
      xaxis: {
        categories: ["1 Ay", "1 Yıl", "3 Yıl", "5 Yıl"],
        labels: {
          style: {
            colors: "#666",
            fontSize: "14px",
            fontFamily: "inherit",
            fontWeight: 500,
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            if (val < 1000) return "";
            return formatCurrency(val);
          },
          style: {
            colors: "#666",
            fontSize: "14px",
            fontFamily: "inherit",
            fontWeight: 500,
          },
        },
      },
      grid: {
        borderColor: "#f1f1f1",
        strokeDashArray: 5,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 10,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return formatCurrency(val);
        },
        style: {
          fontSize: "12px",
          fontFamily: "inherit",
          fontWeight: 600,
          colors: ["#19876A"],
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          padding: 4,
          borderRadius: 4,
          borderWidth: 0,
          opacity: 0.9,
        },
      },
      tooltip: {
        theme: "light",
        y: {
          formatter: function (val) {
            return formatCurrency(val);
          },
        },
        style: {
          fontSize: "14px",
          fontFamily: "inherit",
        },
      },
      markers: {
        size: 6,
        colors: ["#19876A"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 8,
        },
      },
    },
    series: [
      {
        name: "Tahmini Kazanç",
        data: chartDetail,
      },
    ],
  };

  const modelRef = useRef(null);
  const classRef = useRef(null);

  const classOptions = useMemo(() => {
    return selectedBrand?.classes || [];
  }, [selectedBrand]);

  const modelOptions = useMemo(() => {
    return selectedClass?.models || [];
  }, [selectedClass]);

  //calculate
  const handleCalculation = async () => {
    const carDetails = {
      modelId: selectedModel["modelid"],
      fuelTypeId: selectedFuelType,
      bodyTypeId: selectedBodyType,
      gearTypeId: selectedGearType,
      yearId: selectedYear,
      kilometerId: selectedKilometer,
    };

    const res = await makeCalculation({
      carDetails,
    });
    if (res.data.rentalFee == -1) {
      document.getElementById("labelResult").innerText =
        "Aracınız kiralık olarak kabul edilmemektedir.";
      document.getElementById("result").innerText = "";
    } else if (res.data.rentalFee == -2) {
      document.getElementById("labelResult").innerText =
        "Aracınızın kilometre kriteri uyuşmamaktadır.";
      document.getElementById("result").innerText = "";
    } else {
      // Önce sayısal değerleri hesaplayın
      var fee1 = res.data.rentalFee * 0.65;
      var fee2 = res.data.rentalFee * 0.75;

      // Sonra bu değerleri istediğiniz formata dönüştürün
      var formattedFee1 = fee1.toLocaleString("tr-TR", {
        useGrouping: true,
        minimumFractionDigits: 0,
      });
      var formattedFee2 = fee2.toLocaleString("tr-TR", {
        useGrouping: true,
        minimumFractionDigits: 0,
      });

      // Son olarak "₺" simgesini ekleyin ve sonuçları birleştirin
      var result1 = "₺" + formattedFee1;
      var result2 = "₺" + formattedFee2;

      document.getElementById("result").innerText = result1 + " - " + result2;
      document.getElementById("labelResult").innerText =
        "Aylık Tahmini Geliriniz";
    }
  };

  //calculate
  const handleCalculationForCompany = async () => {
    const carDetails = {
      modelId: selectedModel["modelid"],
      fuelTypeId: selectedFuelType,
      bodyTypeId: selectedBodyType,
      gearTypeId: selectedGearType,
      yearId: selectedYear,
      kilometerId: selectedKilometer,
    };

    const res = await makeCalculation({
      carDetails,
    });
    if (res.data.rentalFee == -1) {
      document.getElementById("labelResult").innerText =
        "Aracınız kiralık olarak kabul edilmemektedir.";
      document.getElementById("result").innerText = "";
    } else if (res.data.rentalFee == -2) {
      document.getElementById("labelResult").innerText =
        "Aracınızın kilometre kriteri uyuşmamaktadır.";
      document.getElementById("result").innerText = "";
    } else {
      // Önce sayısal değerleri hesaplayın
      var fee1 = res.data.rentalFee * 0.65 * 1.4;
      var fee2 = res.data.rentalFee * 0.75 * 1.4;

      // Sonra bu değerleri istediğiniz formata dönüştürün
      var formattedFee1 = fee1.toLocaleString("tr-TR", {
        useGrouping: true,
        minimumFractionDigits: 0,
      });
      var formattedFee2 = fee2.toLocaleString("tr-TR", {
        useGrouping: true,
        minimumFractionDigits: 0,
      });

      // Son olarak "₺" simgesini ekleyin ve sonuçları birleştirin
      var result1 = "₺" + formattedFee1;
      var result2 = "₺" + formattedFee2;

      document.getElementById("result").innerText = result1 + " - " + result2;
      document.getElementById("labelResult").innerText =
        "Aylık Tahmini Geliriniz";
    }
  };

  // Para birimi formatında göstermek için fonksiyon
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 0,
    }).format(amount);
  };

  // Binlik ayracı için formatlama fonksiyonu
  const formatNumber = (value) => {
    // Sadece sayıları al ve noktalama işaretlerini kaldır
    const numbers = value.replace(/[^\d]/g, "");
    // Binlik ayracı ekle
    return numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  // Sayıya çevirme fonksiyonu (hesaplama için)
  const parseFormattedNumber = (value) => {
    return value.replace(/\./g, "");
  };

  // TextField için onChange handler
  const handleInvestmentChange = (e) => {
    const value = e.target.value;
    // Noktaları kaldır ve sadece rakamları al
    const numericValue = value.replace(/[^\d]/g, "");

    if (numericValue === "" || /^\d*$/.test(numericValue)) {
      // Sayıyı formatla ve state'e kaydet
      const formattedValue =
        numericValue === "" ? "" : formatNumber(numericValue);
      setInvestmentAmount(formattedValue);
    }
  };

  // Yatırım hesaplama fonksiyonu
  const handleInvestmentCalculation = async () => {
    const numericValue = parseFormattedNumber(investmentAmount);
    const res = await makeInvestmentCalculation(numericValue);
    const resultElement = document.getElementById("yatirim-sonuc");

    if (res.data.investmentId === -1) {
      resultElement.innerText = "*Yatırım tutarına ait sonuç bulunamadı.";
      resultElement.style.color = "red";
      setShowChart(false);
      setChartDetail([]);
    } else {
      resultElement.innerText = "";
      const rawData = [
        res.data.earningHigh,
        res.data.earningHigh * 12,
        res.data.earningHigh * 36,
      ];
      setChartDetail(rawData);
      setShowChart(true);
    }
  };

  const handleInvestmentCalculationForCompany = async () => {
    const numericValue = parseFormattedNumber(investmentAmount);
    const res = await makeInvestmentCalculation(numericValue);
    const resultElement = document.getElementById("yatirim-sonuc");

    if (res.data.investmentId === -1) {
      resultElement.innerText = "*Yatırım tutarına ait sonuç bulunamadı.";
      resultElement.style.color = "red";
      setShowChart(false);
      setChartDetail([]);
    } else {
      resultElement.innerText = "";
      const fee = parseFloat((res.data.earningHigh * 1.4).toFixed(2));
      const dataToPass = [
        fee,
        parseFloat((fee * 12).toFixed(2)),
        parseFloat((fee * 36).toFixed(2)),
      ];
      setChartDetail(dataToPass);
      setShowChart(true);
    }
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const onIstekBırakinClose = () => {
    setDialogOpen(false);
    setDialogConfirmationOpen(true);
  };
  const handleConfirmationClickOpen = () => {
    setDialogConfirmationOpen(true);
  };

  const handleConfirmationClose = () => {
    setDialogConfirmationOpen(false);
    setDialogOpen(false);
  };

  const handleContactWithMe = async () => {
    onIstekBırakinClose();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCarDetails();
        setCarDetails(response.data); // Assuming the API response is an object with a 'data' property
      } catch (error) {
        console.error("Error fetching car details:", error);
      }
    };

    fetchData();
  }, []);

  // Function to convert API data to the structure of brandModel
  const convertToBrandModel = (apiData) => {
    return apiData.map((brand) => ({
      brandid: brand.id,
      brand: brand.label,
      label: brand.label,
      classes: brand.classes.map((carClass) => ({
        classid: carClass.id,
        name: carClass.label,
        label: carClass.label,
        models: carClass.models.map((model) => ({
          modelid: model.id,
          name: model.label,
          label: model.label,
        })),
      })),
    }));
  };

  const brandModelFromApi = convertToBrandModel(carDetails);

  // Slider komponenti
  const PhoneSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const images = [
      "/assets/phone.png",
      "/assets/phone2.png",
      "/assets/phone3.png",
    ];

    useEffect(() => {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000);

      return () => clearInterval(timer);
    }, []);

    const nextSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    };

    const prevSlide = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      );
    };

    const goToSlide = (index) => {
      setCurrentIndex(index);
    };

    return (
      <div className="relative w-full">
        <div className="relative w-full h-[600px] overflow-hidden">
          <AnimatePresence initial={false} mode="wait">
            <motion.img
              key={currentIndex}
              src={images[currentIndex]}
              alt={`App Screenshot ${currentIndex + 1}`}
              className="w-full h-full object-contain"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
            />
          </AnimatePresence>

          {/* Navigation Arrows */}
          <button
            onClick={prevSlide}
            className="absolute left-4 top-1/2 -translate-y-1/2 w-12 h-12 flex items-center justify-center rounded-full bg-white/80 backdrop-blur-sm shadow-lg hover:bg-white transition-all duration-300 group z-10"
          >
            <i className="fas fa-chevron-left text-[#19876A] group-hover:scale-110 transition-transform"></i>
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-4 top-1/2 -translate-y-1/2 w-12 h-12 flex items-center justify-center rounded-full bg-white/80 backdrop-blur-sm shadow-lg hover:bg-white transition-all duration-300 group z-10"
          >
            <i className="fas fa-chevron-right text-[#19876A] group-hover:scale-110 transition-transform"></i>
          </button>
        </div>

        <div className="absolute -bottom-10 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {images.map((_, index) => (
            <button
              key={index}
              onClick={() => goToSlide(index)}
              className={`w-2.5 h-2.5 rounded-full transition-all duration-300 ${
                currentIndex === index
                  ? "bg-[#19876A] w-6"
                  : "bg-[#19876A]/30 hover:bg-[#19876A]/50"
              }`}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div style={props.style}>
      {/* Header Start */}
      <Header topSpace={{ md: true }} type="reverse-scroll ">
        <HeaderNav
          fluid="fluid"
          theme="dark"
          expand="lg"
          className="py-[0px] px-[35px] md:px-[15px] md:py-[20px] sm:px-0"
        >
          {/* Logo Col */}
          <Col lg={2} sm={6} xs={"auto"} className="mr-auto ps-0">
            <Link aria-label="header logo" className="flex items-center" to="/">
              <Navbar.Brand className="inline-block p-0 m-0">
                {/* Logo container with light gradient background */}
                <div className="relative p-3 group">
                  {/* Background gradient overlay */}
                  <div className="absolute inset-0 bg-gradient-to-r from-white/80 to-white/60 backdrop-blur-[2px] rounded-lg transition-all duration-300 group-hover:from-white/90 group-hover:to-white/70 shadow-sm" />

                  {/* Logo with subtle shadow */}
                  <div className="relative">
                    <img
                      className="default-logo"
                      width="111"
                      height="111"
                      src="/assets/img/logo/logo.png"
                      data-rjs="/assets/img/logo/logo.png"
                      alt="logo"
                    />
                    <img
                      className="alt-logo"
                      width="111"
                      height="36"
                      src="/assets/img/logo/logo.png"
                      data-rjs="/assets/img/logo/logo.png"
                      alt="logo"
                    />
                    <img
                      className="mobile-logo"
                      width="111"
                      height="36"
                      src="/assets/img/logo/logo.png"
                      data-rjs="/assets/img/logo/logo.png"
                      alt="logo"
                    />
                  </div>
                </div>
              </Navbar.Brand>
            </Link>
          </Col>

          {/* Hamburger Menu ve İletişim Butonu Container */}
          <div className="flex items-center gap-4">
            {/* İletişim Butonu */}
            <Col
              xs={"auto"}
              className="justify-end pe-0 flex items-center order-1 lg:order-2"
            >
              <MUIButton
                className="!bg-[#19876A] hover:!bg-[#19AA6A] !text-white !font-medium !font-serif !rounded !min-w-[150px] md:!min-w-[120px] sm:!min-w-[100px] xs:!min-w-[90px] xs:!px-2 xs:!text-sm"
                size="medium"
                onClick={handleContactDialogOpen}
              >
                İLETİŞİME GEÇ
              </MUIButton>
            </Col>

            {/* Hamburger Menu */}
            <Navbar.Toggle className="order-2 lg:order-1 md:ml-[17px] w-[25px] min-h-[15px] inline-block align-middle">
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
            </Navbar.Toggle>
          </div>

          {/* Navigation Menu */}
          <Navbar.Collapse className="col-auto col-lg-8 px-lg-0 mx-auto justify-center">
            <ul className="navbar-nav alt-font text-white">
              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="avantajlar"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  Avantajlar
                </ScrollTo>
              </li>
              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="nasil-calisir"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  Araç Yatırımı
                </ScrollTo>
              </li>

              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="arac-kirala"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  Kurumsal Araç Kiralama
                </ScrollTo>
              </li>
              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="sikca-sorulan-sorular"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  Sıkça Sorulan Sorular
                </ScrollTo>
              </li>
              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="hakkimizda"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  Hakkımızda
                </ScrollTo>
              </li>
              <li className="nav-item cursor-pointer">
                <ScrollTo
                  to="iletisim"
                  offset={80}
                  delay={0}
                  spy={true}
                  smooth={true}
                  duration={800}
                  className="inner-link nav-link"
                >
                  İletişim
                </ScrollTo>
              </li>
            </ul>
          </Navbar.Collapse>
        </HeaderNav>
      </Header>

      {/* Header End */}

      {/* Section Start */}
      <StartupPageBannerSlider
        onContactClick={handleContactDialogOpen}
        className="mt-[120px] lg:mt-[100px] md:mt-[80px]"
      />
      {/* Section End */}

      <InViewPort>
        {/* Section Start */}
        <m.section
          className="bg-white py-[120px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px]"
          {...fadeIn}
        >
          <Container>
            <Row className="justify-center">
              <Col md={12} className="text-center mb-[7%]">
                <h2 className="heading-3 font-serif font-semibold text-darkgray inline-block text-center mb-[25px] xs:mb-[30px]">
                  Araç kiralama ile
                </h2>
                <span>
                  <h2 className="heading-3 font-serif font-semibold text-[#19876A] inline-block text-center mb-[25px] xs:mb-[30px]">
                    &nbsp;gelir elde edin
                  </h2>
                </span>
              </Col>
            </Row>
            {/* <Services
              grid="row-cols-1 row-cols-lg-3 row-cols-md-2 gap-y-10 justify-center"
              theme="service-style-02"
              className=""
              data={serviceData2}
              animation={fadeIn}
            /> */}
            <div class="row-cols-1 row-cols-lg-3 row-cols-md-2 gap-y-10 justify-center row">
              {/* Kart 1 - İş Ortağım */}
              <div class="col px-[15px]">
                <div class="service-style-02 group hover:shadow-xl transition-shadow duration-300 h-full flex flex-col">
                  <div className="img-wrapper relative h-[238px] overflow-hidden bg-[#f7f8fc] flex items-center justify-center">
                    <img
                      height="238"
                      width="360"
                      className="object-contain w-[250px] transition-transform duration-500 group-hover:scale-105"
                      src="/assets/isortagim.png"
                      alt="service-style-5"
                    />
                  </div>
                  <div className="service-style p-6 bg-white flex flex-col flex-grow">
                    <span className="title font-medium text-darkgray block font-serif mb-[10px] group-hover:text-[#19876A] transition-colors duration-300">
                      Karlı bir araca yatırım mı yapmak istiyorsunuz?
                    </span>
                    <p className="text-gray-600 leading-relaxed mb-6 flex-grow">
                      Yatırımcılar Folya Filo'dan bir veya daha fazla araç satın
                      alarak aylık düzenli kira geliri elde edebilir,
                      birikimlerini enflasyonun etkilerinden koruyarak aldıkları
                      araçların değer artışından faydalanır. Bu yatırım bireysel
                      ve tüzel kişilik olarak yapılabilir.
                    </p>
                    <div className="info-service mt-auto">
                      <ScrollTo
                        to="aracim-yatirimi"
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={800}
                        className="inline-flex items-center justify-center gap-2 px-6 py-3 bg-[#19876A] hover:bg-[#19AA6A] text-white rounded-md transition-all duration-300 shadow-md hover:shadow-lg font-serif font-medium text-sm uppercase w-full cursor-pointer"
                      >
                        BÜTÇE BELİRLE
                        <i className="ti-arrow-right transform transition-transform duration-300 group-hover:translate-x-1"></i>
                      </ScrollTo>
                    </div>
                  </div>
                </div>
              </div>

              {/* Kart 2 - Paraba */}
              <div class="col px-[15px]">
                <div class="service-style-02 group hover:shadow-xl transition-shadow duration-300 h-full flex flex-col">
                  <div className="img-wrapper relative h-[238px] overflow-hidden bg-[#f7f8fc] flex items-center justify-center">
                    <img
                      height="160"
                      width="300"
                      className="object-contain max-h-[80%] max-w-[80%] transition-transform duration-500 group-hover:scale-105"
                      src="/assets/parabalogo.png"
                      alt="service-style-5"
                    />
                  </div>
                  <div className="service-style p-6 bg-white flex flex-col flex-grow">
                    <span className="title font-medium text-darkgray block font-serif mb-[10px] group-hover:text-[#19876A] transition-colors duration-300">
                      Küçük bütçelerle nasıl araç yatırımı yaparsınız?
                    </span>
                    <p className="text-gray-600 leading-relaxed mb-6 flex-grow">
                      Paraba, yatırımcıların bir aracın tamamını satın almak
                      yerine, belirli bir yüzdesine sahip olmasına olanak tanır.
                      Yatırımcılar araçların sözleşme süreleri boyunca, sahip
                      oldukları hisse oranında aylık kazanç elde ederler.
                    </p>
                    <div className="info-service mt-auto">
                      <a
                        aria-label="services"
                        className="inline-flex items-center justify-center gap-2 px-6 py-3 bg-[#19876A] hover:bg-[#19AA6A] text-white rounded-md transition-all duration-300 shadow-md hover:shadow-lg font-serif font-medium text-sm uppercase w-full"
                        href="https://parabaapp.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PARABA'YA GÖZ AT
                        <i className="ti-arrow-right transform transition-transform duration-300 group-hover:translate-x-1"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* Kart 3 - Destek */}
              <div class="col px-[15px]">
                <div class="service-style-02 group hover:shadow-xl transition-shadow duration-300 h-full flex flex-col">
                  <div className="img-wrapper relative h-[238px] overflow-hidden bg-[#f7f8fc] flex items-center justify-center">
                    <div className="img-wrapper relative h-[238px] overflow-hidden bg-[#f7f8fc] flex items-center justify-center">
                      <img
                        src="/assets/support.png"
                        className="w-[120px] transition-transform duration-500 group-hover:scale-105 filter brightness-0 sepia-100 hue-rotate-130 saturate-[.6] opacity-90"
                        alt="customer support icon"
                        style={{
                          filter:
                            "invert(42%) sepia(87%) saturate(401%) hue-rotate(118deg) brightness(92%) contrast(91%)",
                        }}
                      />
                    </div>
                  </div>
                  <div className="service-style p-6 bg-white flex flex-col flex-grow">
                    <span className="title font-medium text-darkgray block font-serif mb-[10px] group-hover:text-[#19876A] transition-colors duration-300">
                      Sorularınız için buradayız.
                    </span>
                    <p className="text-gray-600 leading-relaxed mb-6 flex-grow">
                      Tüm yatırım sürecinde yanınızda olarak sizlere danışmanlık
                      hizmeti sunmaktayız. Karlı bir yatırımın parçası olmak
                      için hemen iletişime geçin.
                    </p>
                    <div className="info-service mt-auto">
                      <ScrollTo
                        to="iletisim"
                        offset={80}
                        delay={0}
                        spy={true}
                        smooth={true}
                        duration={800}
                        className="inline-flex items-center justify-center gap-2 px-6 py-3 bg-[#19876A] hover:bg-[#19AA6A] text-white rounded-md transition-all duration-300 shadow-md hover:shadow-lg font-serif font-medium text-sm uppercase w-full cursor-pointer"
                      >
                        İLETİŞİM
                        <i className="ti-arrow-right transform transition-transform duration-300 group-hover:translate-x-1"></i>
                      </ScrollTo>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </m.section>
        {/* Section End */}

        {/* Section Start */}
        <m.section
          id="arac-kirala"
          className="py-[140px] lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] relative bg-gradient-to-b from-[#f7f8fc] to-white"
          style={{
            backgroundImage: "url(/assets/img/logo/uzundonem.webp)",
            backgroundBlendMode: "overlay",
          }}
        >
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-darkslateblue/80 to-darkslateblue/60"></div>
          <Container className="relative">
            <Row className="justify-center">
              <Col xl={6} lg={7} className="text-center mb-[4.5rem] md:mb-12">
                <h4 className="font-serif font-semibold text-white">
                  Kurumsal Yol Arkadaşınız
                </h4>
                <h5 className="font-serif mb-[5px] -tracking-[.5px] text-white block">
                  Uzun Dönem Araç Kiralamanın Güvenilir Adresi!
                </h5>

                <MUIButton
                  className="!bg-[#19876A] hover:!bg-[#19AA6A] !text-white !mt-8 !ml-2 !mr-2 mx-[10px] font-medium font-serif uppercase rounded !min-w-[200px] !min-h-[60px] !text-xl !py-4 !px-8 border-[2px] border-solid md:!mb-12 md:!mt-8 md:!w-full @media (max-width: 768px) { md:!w-3/4 }"
                  size="lg"
                  onClick={handleClickOpen}
                  variant="outlined"
                >
                  TEKLİF AL
                </MUIButton>
              </Col>
            </Row>
          </Container>
          <Dialog
            open={dialogOpen}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth
          >
            {/* <Container className="flex flex-col w-fit !place-content-evenly !justify-center !items-center bg-white p-4 rounded mt-4 md:!w-[70%] sm:!w-[85%] xs:!w-full "> */}
            <Container className="flex flex-col w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] 2xl:w-[50%] !place-content-evenly !justify-center !items-center bg-white p-4 rounded mt-4">
              <Row className="justify-center">
                <Col className="text-center">
                  <DialogTitle className="text-large">
                    {" "}
                    Yetkili Kişi Bilgileri
                  </DialogTitle>
                  <DialogContentText>
                    Aşağıdaki yetkili kişi bilgilerini eksiksiz doldurunuz.
                  </DialogContentText>
                </Col>
              </Row>
              <DialogContent>
                <div>
                  <Grid container spacing={3}>
                    {/* İsim */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="name"
                        name="name"
                        label="Adınız"
                        fullWidth
                        variant="outlined"
                        value={formData.name}
                        onChange={handleChangex}
                        helperText={errorMessages.name}
                        error={!!errorMessages.name}
                      />
                    </Grid>

                    {/* Soyad */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="surname"
                        name="surname"
                        label="Soyadınız"
                        fullWidth
                        variant="outlined"
                        value={formData.surname}
                        onChange={handleChangex}
                        helperText={errorMessages.surname}
                        error={!!errorMessages.surname}
                      />
                    </Grid>

                    {/* Telefon Numarası */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Telefon Numaranız"
                        fullWidth
                        variant="outlined"
                        value={formData.phoneNumber}
                        onChange={handleChangex}
                        helperText={errorMessages.phoneNumber}
                        error={!!errorMessages.phoneNumber}
                      />
                    </Grid>

                    {/* E-Posta */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="email"
                        name="email"
                        label="E-Postanız"
                        fullWidth
                        variant="outlined"
                        value={formData.email}
                        onChange={handleChangex}
                        helperText={errorMessages.email}
                        error={!!errorMessages.email}
                      />
                    </Grid>
                  </Grid>
                  <div>
                    <Row className="justify-center mb-4">
                      <Col className="text-center">
                        <DialogTitle className="text-large">
                          {" "}
                          Şirket Bilgileri*
                        </DialogTitle>
                        <DialogContentText>
                          Uzun dönem araç kiralaması yapmak için lütfen
                          aşağıdaki şirket bilgilerini eksiksiz doldurunuz.
                        </DialogContentText>
                      </Col>
                    </Row>

                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="il"
                          name="il"
                          label="İl"
                          fullWidth
                          variant="outlined"
                          value={formData.il}
                          onChange={handleChangex}
                          helperText={errorMessages.il}
                          error={!!errorMessages.il}
                          placeholder="İl yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="ilce"
                          name="ilce"
                          label="İlce"
                          fullWidth
                          variant="outlined"
                          value={formData.ilce}
                          onChange={handleChangex}
                          helperText={errorMessages.ilce}
                          error={!!errorMessages.ilce}
                          placeholder="İl��e yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          id="firmawebsitesi"
                          name="firmawebsitesi"
                          label="Firma Web Sitesi"
                          value={formData.firmawebsitesi}
                          onChange={handleChangex} // Bu satırı ekleyin
                          variant="outlined"
                          fullWidth
                          placeholder="Web sitenizi yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="vergiil"
                          name="vergiil"
                          label="Vergi Dairesi İl"
                          fullWidth
                          variant="outlined"
                          value={formData.vergiil}
                          onChange={handleChangex}
                          helperText={errorMessages.vergiil}
                          error={!!errorMessages.vergiil}
                          placeholder="Vergi Daire l yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="vergiilce"
                          name="vergiilce"
                          label="Vergi Dairesi İlçe"
                          fullWidth
                          variant="outlined"
                          value={formData.vergiilce}
                          onChange={handleChangex}
                          helperText={errorMessages.vergiilce}
                          error={!!errorMessages.vergiilce}
                          placeholder="Vergi Daire İlçe yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        {/* <TextField
                            label="Firma Web Sitesi"
                            variant="outlined
                            fullWidth
                            placeholder="Web sitenizi yazınız"
                          /> */}
                        <TextField
                          id="vergino"
                          name="vergino"
                          label="Vergi Numarası*"
                          variant="outlined"
                          fullWidth
                          value={formData.vergino}
                          onChange={handleChangex}
                          helperText={errorMessages.vergino}
                          error={!!errorMessages.vergino}
                          placeholder="_ _ _ _ _ _ _ _ _ _"
                          inputProps={{ maxLength: 9 }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <Row className="justify-center mb-4">
                      <Col className="text-center">
                        <DialogTitle className="text-large">
                          {" "}
                          Ara Bilgileri*
                        </DialogTitle>
                        <DialogContentText>
                          Teklif almak istediğiniz araç bilgilerini eksiksiz
                          doldurunuz.
                        </DialogContentText>
                      </Col>
                    </Row>

                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="kiralamasuresi"
                          name="kiralamasuresi"
                          label="Kiralama Süresi"
                          fullWidth
                          variant="outlined"
                          value={formData.kiralamasuresi}
                          onChange={handleChangex}
                          helperText={errorMessages.kiralamasuresi}
                          error={!!errorMessages.kiralamasuresi}
                          placeholder="Kiralama Süresi yazınız, Örn: 12 Ay"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="kiralanacakaracsayisi"
                          name="kiralanacakaracsayisi"
                          label="Kiralanacak Araç Sayısı"
                          fullWidth
                          variant="outlined"
                          value={formData.kiralanacakaracsayisi}
                          onChange={handleChangex}
                          helperText={errorMessages.kiralanacakaracsayisi}
                          error={!!errorMessages.kiralanacakaracsayisi}
                          placeholder="Kiralanacak Araç Sayısı yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Araç Markası"
                          required
                          id="kiralanacakaracmarka"
                          name="kiralanacakaracmarka"
                          fullWidth
                          variant="outlined"
                          value={formData.kiralanacakaracmarka}
                          onChange={handleChangex}
                          helperText={errorMessages.kiralanacakaracmarka}
                          error={!!errorMessages.kiralanacakaracmarka}
                          placeholder="Araç Markası Yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          label="Araç Modeli"
                          required
                          id="kiralanacakaracmodel"
                          name="kiralanacakaracmodel"
                          fullWidth
                          variant="outlined"
                          value={formData.kiralanacakaracmodel}
                          onChange={handleChangex}
                          helperText={errorMessages.kiralanacakaracmodel}
                          error={!!errorMessages.kiralanacakaracmodel}
                          placeholder="Araç Modeli Yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          id="aracyillikkm"
                          name="aracyillikkm"
                          value={formData.aracyillikkm}
                          helperText={errorMessages.aracyillikkm}
                          error={!!errorMessages.aracyillikkm}
                          onChange={handleChangex} // Bu satırı ekleyin
                          label="Yıllık KM Limiti"
                          variant="outlined"
                          fullWidth
                          placeholder="Yıllık KM Yazınız"
                        />
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <TextField
                          id="not"
                          name="not"
                          value={formData.not}
                          onChange={handleChangex} // Bu satırı ekleyin
                          label="Not"
                          variant="outlined"
                          fullWidth
                          placeholder="Not Yazınız"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <p className="text-center mt-2">
                  Butona tıklayarak gizlilik sözleşmesini kabul etmiş olursunuz.
                </p>
              </DialogContent>
              <DialogActions>
                <MUIButton
                  className="disabled:!bg-gray-300 disabled:!text-gray-600 disabled:!border-gray-400 !bg-green-800 text-white mx-[10px] font-large font-serif uppercase rounded !min-w-[200px] md:!mb-12  md:!w-full"
                  size="large"
                  onClick={handleSubmit}
                >
                  TEKLİF AL
                </MUIButton>
              </DialogActions>
            </Container>
          </Dialog>
          <Dialog
            open={errorDialogOpen}
            onClose={() => setErrorDialogOpen(false)}
          >
            <DialogTitle>Hata</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Lütfen tüm zorunlu alanları doldurunuz.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={() => setErrorDialogOpen(false)} color="primary">
                  Tamam
                </Button> */}
            </DialogActions>
          </Dialog>
          <Dialog
            open={dialogConfirmationOpen}
            onClose={handleConfirmationClose}
          >
            <Container className="flex flex-col w-fit !place-content-evenly !justify-center !items-center bg-white p-4 rounded md:!w-[70%] sm:!w-[85%] xs:!w-full">
              <Row className="justify-center">
                <Col className="text-center">
                  <IconWithText
                    theme="icon-with-text-03"
                    data={IconWithTextData_03}
                    animation={fadeIn}
                    animationDelay={0.2}
                  />
                </Col>
              </Row>
            </Container>
          </Dialog>
        </m.section>
        {/* Section End */}

        {/* Section Start - App Showcase */}
        <section className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] bg-gradient-to-b from-white to-[#f7f8fc] overflow-hidden">
          <Container>
            <Row className="justify-center">
              <Col lg={10} className="text-center mb-20">
                <span className="font-serif font-medium text-[#19876A] uppercase tracking-[1px] block mb-[5px]">
                  PARABA MOBİL UYGULAMA
                </span>
                <h2 className="heading-4 font-serif font-semibold text-darkgray mb-[25px]">
                  Yatırımınızı Cebinizden Yönetin
                </h2>
                <p className="text-gray-600 text-lg">
                  Paraba mobil uygulaması ile araç yatırımlarınızı kolayca takip
                  edin
                </p>
              </Col>
            </Row>

            <Row className="items-center">
              {/* Sol Taraf - Özellik Kartları */}
              <Col lg={4} className="space-y-8">
                {featureCards.left.map((feature, index) => (
                  <FeatureCard key={index} feature={feature} isRight={false} />
                ))}
              </Col>

              {/* Orta - Telefon Görüntüsü */}
              <Col lg={4} className="relative px-8 py-12">
                <m.div
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                  viewport={{ once: true }}
                >
                  <PhoneSlider />
                </m.div>
              </Col>

              {/* Sağ Taraf - Özellik Kartları */}
              <Col lg={4} className="space-y-8">
                {featureCards.right.map((feature, index) => (
                  <FeatureCard key={index} feature={feature} isRight={true} />
                ))}
              </Col>
            </Row>

            {/* App Store Butonları */}
            <Row className="justify-center mt-20">
              <Col lg={8} className="text-center">
                <p className="text-gray-600 mb-8">
                  Hemen indirin, yatırımlarınızı mobil cihazınızdan yönetmeye
                  başlayın
                </p>
                <div className="flex justify-center gap-4">
                  <a
                    href="https://parabaapp.com/"
                    className="bg-black text-white px-6 py-3 rounded-xl flex items-center space-x-3 hover:bg-gray-900 transition-colors duration-300"
                  >
                    <i className="fab fa-apple text-2xl"></i>
                    <div className="text-left">
                      <div className="text-lg font-medium">App Store</div>
                    </div>
                  </a>
                  <a
                    href="https://parabaapp.com/"
                    className="bg-black text-white px-6 py-3 rounded-xl flex items-center space-x-3 hover:bg-gray-900 transition-colors duration-300"
                  >
                    <i className="fab fa-google-play text-2xl"></i>
                    <div className="text-left">
                      <div className="text-lg font-medium">Google Play</div>
                    </div>
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* Section Start */}
        <section
          id="nasil-calisir"
          className="py-[8px] lg:py-[90px] md:py-[75px] sm:py-[50px] overflow-hidden startup-processstep bg-gradient-to-b from-white to-[#f7f8fc]"
        >
          <Container>
            {/* Başlık bölümü */}
            <Row className="justify-center mb-[7%]">
              <Col md={12} className="text-center">
                <h2 className="heading-5 font-serif font-semibold text-[#19876A] block -tracking-[.5px] mb-4">
                  Araç Yatırım Süreci
                </h2>
                <h3 className="text-2xl font-serif text-darkgray">
                  Nasıl İşliyor?
                </h3>
              </Col>
            </Row>

            {/* İçerik bölümü */}
            <Row className="items-center justify-center">
              <Col xl={5} lg={6} md={10}>
                <div className="relative">
                  {/* Process steps - başlıklar kaldırıldı */}
                  <div className="process-steps">
                    {ProcessStepData.map((step, index) => (
                      <m.div
                        key={index}
                        className="process-step-item mb-10 last:mb-0"
                        initial={{ opacity: 0, y: 30 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.2 }}
                        viewport={{ once: true }}
                      >
                        <div className="flex items-start group hover:translate-x-3 transition-transform duration-300">
                          <div className="process-step-number mr-6 relative">
                            <div className="w-14 h-14 rounded-full bg-[#19876A]/10 flex items-center justify-center group-hover:bg-[#19876A] transition-colors duration-300">
                              <span className="text-[#19876A] text-2xl font-bold group-hover:text-white transition-colors duration-300">
                                {index + 1}
                              </span>
                            </div>
                            {index !== ProcessStepData.length - 1 && (
                              <div className="absolute left-1/2 top-full w-[2px] h-20 bg-[#19876A]/10 -translate-x-1/2"></div>
                            )}
                          </div>
                          <div className="process-step-content flex-1">
                            <h4 className="font-serif text-lg font-medium text-darkgray mb-2">
                              {step.title}
                            </h4>
                            <p className="text-gray-600 leading-relaxed">
                              {step.content}
                            </p>
                          </div>
                        </div>
                      </m.div>
                    ))}
                  </div>
                </div>
              </Col>
              <m.div
                className="offset-xl-1 col-lg-6 md:mt-[50px]"
                {...fadeInLeft}
              >
                <div className="relative">
                  <img
                    src="/assets/img/logo/experimental-how-much-earn.webp"
                    width={809}
                    height={538}
                    className="w-full h-auto rounded-xl shadow-md hover:shadow-lg hover:scale-[1.02] transition-all duration-500"
                    alt="Araç yatırım süreci"
                  />
                </div>
              </m.div>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <section
          id="hakkimizda"
          className="bg-gradient-to-b from-[#f7f8fc] to-white bg-center py-[200px] lg:py-[160px] md:py-[110px] sm:py-[50px] startup-iconwithtext"
        >
          <Container>
            <div className="mb-[105px] md:mb-[70px] sm:mb-[50px]">
              <Overlap className="md:mt-0">
                <Row className="justify-center">
                  <Col xs={12} sm={9} lg={12} md={12}>
                    <IconWithText
                      grid="row-cols-1 row-cols-lg-3 row-cols-md-2 justify-center gap-y-10 z-10 relative"
                      className="rounded-[4px] flex"
                      theme="icon-with-text-04"
                      data={iconWithTextData}
                    />
                  </Col>
                </Row>
              </Overlap>
            </div>
            <Row className="items-end overflow-hidden">
              <m.div
                className="col-12 col-lg-3 col-md-6 order-1 text-right md:mb-[50px] md:text-center"
                {...fadeInRight}
              >
                <div className="text-[70px] text-[#19876A] font-serif leading-[70px] tracking-[-3px] font-semibold">
                  175+
                </div>
                <span className="font-serif text-darkgray font-medium uppercase tracking-[2px] block mb-[15px] sm:mb-[5px]">
                  Mutlu YATIRIMCI
                </span>
                <p className="w-[90%] inline-block sm:w-[60%] xs:w-full">
                  Folya Filo,araç satın alma ve kiralama sektörüne yeni,müşteri
                  odaklı bir perspektif sunmayı amaçlayan araç kiralama
                  firmasıdır
                </p>
              </m.div>
              <m.div
                className="col col-lg-6 order-lg-2 order-3 z-0 py-[10px]"
                {...{ ...fadeIn, transition: { duration: 0.6 } }}
              >
                <TiltBox>
                  <h1 className=" text-[#19876A] font-serif inline-block font-serif mb-0 uppercase font-semibold tracking-[-10px] text-[300px] lg:text-[300px] leading-[260px] xs:text-[160px] xs:leading-[150px]">
                    10
                  </h1>
                  <span className="font-serif text-xlg text-darkgray tracking-[4px] font-semibold uppercase block xs:text-md">
                    YILLIK SEKTÖREL TECRÜBE
                  </span>
                </TiltBox>
              </m.div>
              <m.div
                className="col-12 col-lg-3 col-md-6 order-2 md:mb-[50px] md:text-center"
                {...fadeInLeft}
              >
                <div className="text-[70px] text-[#19876A] font-serif leading-[70px] tracking-[-3px] font-semibold">
                  100+
                </div>
                <span className="font-serif text-darkgray font-medium uppercase tracking-[2px] block mb-[15px] sm:mb-[5px]">
                  KıRADAKİ ARAÇ SAYISI
                </span>
                <p className="w-[90%] inline-block sm:w-[60%] xs:w-full">
                  Deneyimimize, dinamizmimize ve yeteneklerimize güveniyor;
                  sizin için en kârlı araç kiralama ortaklığını sunmayı
                  vadediyoruz.
                </p>
              </m.div>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <section
          id="aracim-yatirimi"
          className="bg-gradient-to-b from-white to-[#f7f8fc] py-[160px] overflow-hidden lg:py-[120px] md:py-[95px] sm:py-[80px] xs:py-[50px] relative"
        >
          {/* Dalga SVG'si ekleyelim */}
          <div className="absolute top-0 left-0 w-full">
            <svg
              className="w-full h-[150px] transform rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              preserveAspectRatio="none"
            >
              <path
                fill="#f7f8fc"
                fillOpacity="1"
                d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,112C672,96,768,96,864,112C960,128,1056,160,1152,160C1248,160,1344,128,1392,112L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
              ></path>
            </svg>
          </div>

          <Container>
            <Row className="items-center justify-center">
              {/* Sol taraf - Hesaplama alanı */}
              <m.div
                className={`col ${
                  showChart ? "col-xl-4 col-lg-5" : "col-xl-6 col-lg-8"
                } col-md-10 md:mb-20`}
                {...fadeIn}
              >
                {/* Başlık ve açıklama */}
                <div className="mb-8 bg-white/80 backdrop-blur-sm p-8 rounded-xl shadow-md hover:shadow-lg transition-all duration-300 border border-[#19876A]/10">
                  <h2 className="heading-5 font-serif text-[#2f2f2f] font-bold tracking-[-1px] mb-4">
                    Araç yatırımı yaparak{" "}
                    <span className="text-[#289474]">
                      yıllık ne kadar gelir
                    </span>{" "}
                    elde edeceksiniz?
                  </h2>
                  <p className="text-gray-600 leading-relaxed">
                    Aylık tahmini kira gelirinizi hesaplamak için yatırım
                    tutarınızı girin.
                  </p>
                </div>

                {/* Bilgilendirme kutusu */}
                <div className="bg-gradient-to-r from-[#19876A]/10 to-[#19876A]/5 rounded-lg p-6 mb-8 backdrop-blur-sm border border-[#19876A]/20">
                  <blockquote className="border-l-[4px] text-darkgray font-medium border-[#19876A] text-xmd pl-[25px] pr-0">
                    Araba satın alarak düzenli araç kiralama geliri elde
                    edebilirsiniz. Folya Filo'ya ulaşın, mali durumunuza ve
                    beklentilerinize göre en doğru araç yatırımını yapın.
                  </blockquote>
                </div>

                {/* Input ve butonlar */}
                <div className="space-y-4">
                  <TextField
                    className="w-full"
                    size="medium"
                    placeholder="Yatırım Tutarı"
                    value={investmentAmount}
                    onChange={handleInvestmentChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span className="text-[#19876A] font-medium text-lg">
                            ₺
                          </span>
                        </InputAdornment>
                      ),
                      className: `
                          bg-white/90 
                          backdrop-blur-sm 
                          shadow-md hover:shadow-lg 
                          transition-all duration-300
                          rounded-xl
                          !text-lg
                          h-[52px]
                        `,
                      sx: {
                        "& fieldset": {
                          borderColor: "rgba(25, 135, 106, 0.2)",
                          borderWidth: "1px",
                          transition: "all 0.3s ease",
                        },
                        "&:hover fieldset": {
                          borderColor: "rgba(25, 135, 106, 0.5) !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#19876A !important",
                          borderWidth: "2px !important",
                        },
                        "& input": {
                          padding: "14px",
                          fontSize: "16px",
                          fontWeight: 500,
                          color: "#2f2f2f",
                        },
                        "& input::placeholder": {
                          color: "rgba(0, 0, 0, 0.4)",
                          opacity: 1,
                        },
                      },
                    }}
                    variant="outlined"
                    FormHelperTextProps={{
                      sx: {
                        marginLeft: "14px",
                      },
                    }}
                  />

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <MUIButton
                      onClick={() => handleInvestmentCalculation()}
                      className="
                          !bg-gradient-to-r !from-[#19876A] !to-[#1A9A6A] 
                          hover:!from-[#19876A] hover:!to-[#147A5A]
                          !text-white 
                          !py-4 
                          !px-8 
                          !rounded-xl
                          !font-medium 
                          !tracking-wide
                          !shadow-lg hover:!shadow-xl
                          !transform hover:!scale-[1.02]
                          !transition-all !duration-300
                          !flex !items-center !justify-center !gap-2
                          disabled:!opacity-60 
                          disabled:!cursor-not-allowed 
                          disabled:!bg-gray-400
                          disabled:hover:!scale-100
                          !text-[15px]
                          !min-h-[52px]
                        "
                      variant="contained"
                      fullWidth
                      disabled={
                        !investmentAmount || investmentAmount.trim() === ""
                      }
                    >
                      <span>ŞAHIS İÇİN HESAPLA</span>
                      <i className="fas fa-arrow-right transition-transform group-hover:translate-x-1"></i>
                    </MUIButton>

                    <MUIButton
                      onClick={() => handleInvestmentCalculationForCompany()}
                      className="
                          !bg-gradient-to-r !from-[#19876A] !to-[#1A9A6A]
                          hover:!from-[#19876A] hover:!to-[#147A5A]
                          !text-white 
                          !py-4 
                          !px-8 
                          !rounded-xl
                          !font-medium 
                          !tracking-wide
                          !shadow-lg hover:!shadow-xl
                          !transform hover:!scale-[1.02]
                          !transition-all !duration-300
                          !flex !items-center !justify-center !gap-2
                          disabled:!opacity-60 
                          disabled:!cursor-not-allowed 
                          disabled:!bg-gray-400
                          disabled:hover:!scale-100
                          !text-[15px]
                          !min-h-[52px]
                        "
                      variant="contained"
                      fullWidth
                      disabled={
                        !investmentAmount || investmentAmount.trim() === ""
                      }
                    >
                      <span>ŞİRKET İÇİN HESAPLA</span>
                      <i className="fas fa-arrow-right transition-transform group-hover:translate-x-1"></i>
                    </MUIButton>
                  </div>
                </div>

                {/* Sonuç mesajı */}
                <p
                  id="yatirim-sonuc"
                  className="mt-4 text-center font-medium text-red-500"
                ></p>
              </m.div>

              {/* Grafik */}
              {showChart && (
                <m.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}
                  // Container'ı genişletiyoruz
                  className="col-12 col-xl-8 col-lg-9 md:mt-10"
                >
                  <div className="bg-white rounded-2xl p-8 shadow-lg border border-[#19876A]/10 hover:shadow-xl transition-all duration-500">
                    {/* Başlık Alanı */}
                    <div className="mb-8">
                      <h3 className="font-serif text-2xl font-semibold text-darkgray text-center mb-2">
                        Tahmini Yatırım Getirisi
                      </h3>
                      <div className="flex justify-center items-center gap-6 mt-4">
                        {/* Toplam Yatırım */}
                        <div className="text-center">
                          <p className="text-sm text-gray-500 mb-1">
                            Toplam Yatırım
                          </p>
                          <p className="text-lg font-semibold text-darkgray">
                            {formatCurrency(
                              parseFormattedNumber(investmentAmount)
                            )}
                          </p>
                        </div>
                        {/* Ayraç */}
                        <div className="h-10 w-[1px] bg-gray-200"></div>
                        {/* 5 Yıllık Getiri */}
                        <div className="text-center">
                          <p className="text-sm text-gray-500 mb-1">
                            5 Yıllık Tahmini Getiri
                          </p>
                          <p className="text-lg font-semibold text-[#19876A]">
                            {formatCurrency(chartDetail[2] || 0)}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* Chart Alanı */}
                    <Chart
                      options={{
                        ...investmenetChartDetails.options,
                        chart: {
                          ...investmenetChartDetails.options.chart,
                          height: 500, // Yüksekliği artırdık
                        },
                        colors: ["#19876A"],
                        fill: {
                          type: "gradient",
                          gradient: {
                            shade: "light",
                            type: "vertical",
                            shadeIntensity: 0.5,
                            gradientToColors: ["#19876A"],
                            inverseColors: false,
                            opacityFrom: 0.7,
                            opacityTo: 0.1,
                            stops: [0, 100],
                          },
                        },
                        stroke: {
                          curve: "smooth",
                          width: 3,
                        },
                        grid: {
                          borderColor: "#f1f1f1",
                          strokeDashArray: 5,
                          padding: {
                            top: 0,
                            right: 30,
                            bottom: 0,
                            left: 30,
                          },
                        },
                        markers: {
                          size: 6,
                          colors: ["#19876A"],
                          strokeColors: "#fff",
                          strokeWidth: 2,
                          hover: {
                            size: 8,
                            sizeOffset: 3,
                          },
                        },
                        xaxis: {
                          categories: ["1 Ay", "1 Yıl", "3 Yıl", "5 Yıl"],
                          labels: {
                            style: {
                              colors: "#666",
                              fontSize: "14px",
                              fontFamily: "inherit",
                              fontWeight: 500,
                            },
                          },
                          axisBorder: {
                            show: false,
                          },
                          axisTicks: {
                            show: false,
                          },
                        },
                        yaxis: {
                          labels: {
                            formatter: function (val) {
                              return formatCurrency(val);
                            },
                            style: {
                              colors: "#666",
                              fontSize: "14px",
                              fontFamily: "inherit",
                            },
                          },
                          tooltip: {
                            theme: "light",
                            custom: function ({
                              series,
                              seriesIndex,
                              dataPointIndex,
                              w,
                            }) {
                              const value = series[seriesIndex][dataPointIndex];
                              const category =
                                w.globals.categoryLabels[dataPointIndex];
                              return (
                                '<div class="p-3 bg-white shadow-md rounded-lg border border-gray-100">' +
                                '<div class="text-sm text-gray-500 mb-1">' +
                                category +
                                "</div>" +
                                '<div class="text-lg font-semibold text-[#19876A]">' +
                                formatCurrency(value) +
                                "</div>" +
                                "</div>"
                              );
                            },
                          },
                          dataLabels: {
                            enabled: true,
                            formatter: function (val) {
                              return formatCurrency(val);
                            },
                            style: {
                              fontSize: "12px",
                              fontFamily: "inherit",
                              fontWeight: 600,
                              colors: ["#19876A"],
                            },
                            background: {
                              enabled: true,
                              foreColor: "#fff",
                              padding: 4,
                              borderRadius: 4,
                              borderWidth: 0,
                              opacity: 0.9,
                            },
                            offsetY: -10,
                          },
                        },
                      }}
                      series={[
                        {
                          name: "Tahmini Kazanç",
                          data: chartDetail,
                        },
                      ]}
                      type="area"
                      height={500} // Chart yüksekliıini artırdık
                    />

                    {/* Alt Bilgi */}
                    <div className="mt-6 text-center">
                      <p className="text-sm text-gray-500">
                        * Tahmini getiriler piyasa koşullarına göre değişiklik
                        gösterebilir.
                      </p>
                    </div>
                  </div>
                </m.div>
              )}
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <section
          id="avantajlar"
          className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] bg-gradient-to-b from-[#f7f8fc] to-white"
        >
          <Container>
            <Row className="justify-center">
              <Col md={12} className="text-center mb-[7%]">
                {/* Başlık kısmı */}
                <h2 className="heading-3 font-serif font-semibold text-darkgray inline-block text-center mb-[25px] xs:mb-[30px]">
                  Folya Filo ile
                </h2>
                <span>
                  <h2 className="heading-3 font-serif font-semibold text-[#19876A] inline-block text-center mb-[25px] xs:mb-[30px]">
                    &nbsp;düzenli gelir elde edin
                  </h2>
                </span>
                <p className="text-gray-600 text-lg font-serif max-w-[800px] mx-auto mt-4">
                  Arabanızı kiraya vererek düzenli gelir elde edebileceğinizi
                  biliyor muydunuz?
                  <span className="text-[#19876A] font-medium block mt-2">
                    Siz de doğru araç yatırımını yaparak her ay yüksek kazançlar
                    elde edebilirsiniz.
                  </span>
                </p>
              </Col>
            </Row>

            <Row className="justify-center">
              <Col lg={12} md={9} xs={12}>
                <IconWithText
                  grid="row-cols-1 row-cols-lg-2 gap-y-10 justify-center"
                  theme="icon-with-text-02"
                  data={IconWithTextData_02}
                  animation={fadeIn}
                  animationDelay={0.2}
                  className="[&>div]:bg-white [&>div]:rounded-2xl [&>div]:p-8 [&>div]:shadow-sm hover:[&>div]:shadow-lg [&>div]:transition-all [&>div]:duration-500 [&>div]:border [&>div]:border-[#19876A]/5 hover:[&>div]:border-[#19876A]/20"
                  iconWrapperClassName="!bg-[#19876A]/10 !rounded-xl !p-4 group-hover:!bg-[#19876A]/20 !transition-colors !duration-300"
                  titleClassName="!font-serif !text-xl !font-semibold !text-darkgray !mb-3 group-hover:!text-[#19876A] !transition-colors !duration-300"
                  contentClassName="!text-gray-600 !leading-relaxed"
                />
              </Col>
            </Row>
          </Container>
        </section>
        {/* Section End */}

        {/* Section Start - SSS */}
        <section className="bg-gradient-to-b from-white to-[#f7f8fc]">
          <m.section className="py-20" id="sikca-sorulan-sorular">
            <Container>
              <Row className="justify-center">
                <Col lg={12} md={12}>
                  <h2 className="heading-4 font-serif font-semibold text-[#19876A] text-center mb-[65px] xs:mb-[30px]">
                    Sıkça Sorulan Sorular
                  </h2>

                  <Tabs
                    defaultActiveKey="bireysel"
                    className="mb-8 justify-center custom-tabs"
                  >
                    <Tab
                      eventKey="bireysel"
                      title={
                        <span className="font-serif text-lg font-medium">
                          PARABA
                        </span>
                      }
                      className="text-center"
                    >
                      <Row className="justify-center">
                        <Col lg={8} md={10}>
                          <Accordion
                            theme="accordion-style-02"
                            className="mb-12"
                            themeColor="light"
                            data={AccordionDataBireysel.slice(0, visibleItems)}
                          />
                          {visibleItems < AccordionDataBireysel.length && (
                            <div className="text-center mt-4">
                              <MUIButton
                                className="!bg-[#19876A] hover:!bg-[#19AA6A] text-white w-[40%] !item-center"
                                variant="outlined"
                                onClick={showMoreItems}
                              >
                                Daha Fazla Yükle
                              </MUIButton>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Tab>

                    <Tab
                      eventKey="kurumsal"
                      title={
                        <span className="font-serif text-lg font-medium">
                          İŞ ORTAĞIM
                        </span>
                      }
                      className="text-center"
                    >
                      <Row className="justify-center">
                        <Col lg={8} md={10}>
                          <Accordion
                            theme="accordion-style-02"
                            className="mb-12"
                            themeColor="light"
                            data={AccordionDataKurumsal.slice(0, visibleItems)}
                          />
                          {visibleItems < AccordionDataKurumsal.length && (
                            <div className="text-center mt-4">
                              <MUIButton
                                className="!bg-[#19876A] hover:!bg-[#19AA6A] text-white w-[40%] !item-center"
                                variant="outlined"
                                onClick={showMoreItems}
                              >
                                Daha Fazla Yükle
                              </MUIButton>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Container>
          </m.section>
        </section>
        {/* Section End */}

        {/* Section Start */}
        <section
          id="iletisim"
          className="py-[130px] lg:py-[90px] md:py-[75px] sm:py-[50px] relative bg-gradient-to-b from-[#f7f8fc] to-white"
        >
          {/* Dekoratif Arka Plan Deseni */}
          <div className="absolute inset-0 opacity-[0.03]">
            <div
              className="h-full w-full"
              style={{
                backgroundImage:
                  "url('data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23198765' fill-opacity='1'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E')",
              }}
            />
          </div>

          <Container className="relative">
            {/* Başlık */}
            <Row className="justify-center mb-20">
              <Col xl={6} lg={7} className="text-center">
                <span className="font-serif font-medium text-[#19876A] uppercase tracking-[1px] block mb-[5px]">
                  - FOLYA FİLO -
                </span>
                <h2 className="heading-4 font-serif font-semibold text-darkgray mb-[25px]">
                  Yardıma ihtiyacınız mı var?
                </h2>
                <p className="text-gray-600 text-lg">
                  Size en iyi şekilde yardımcı olabilmemiz için bize ulaşın
                </p>
              </Col>
            </Row>

            {/* İletişim Kartları */}
            <Row className="justify-center">
              <Col xs={12} md={11} lg={10}>
                <Row className="flex-column flex-lg-row g-4">
                  {" "}
                  {/* Mobilde alt alta, lg breakpoint'ten sonra yan yana */}
                  {/* Harita Kartı */}
                  <Col xs={12}>
                    <div className="h-full bg-white rounded-2xl shadow-lg overflow-hidden border border-[#19876A]/10 hover:shadow-xl transition-all duration-500">
                      {/* Harita Başlığı */}
                      <div className="p-6 border-b border-gray-100">
                        <h3 className="font-serif text-xl font-semibold text-darkgray mb-2">
                          Ofisimiz
                        </h3>
                        <p className="text-gray-600">
                          Bizi ziyaret edin veya iletişime geçin
                        </p>
                      </div>

                      {/* Harita */}
                      <div className="h-[400px] relative overflow-hidden">
                        <GoogleMap />
                      </div>

                      {/* İletişim Bilgileri */}
                      <div className="p-6 bg-[#19876A]/5">
                        <div className="flex flex-col space-y-4">
                          <div className="flex items-center space-x-3">
                            <i className="fas fa-map-marker-alt text-[#19876A]"></i>
                            <a href="https://www.google.com.tr/maps/dir/40.9141248,29.2159488/Folya+Filo+Kiralama+A.%C5%9E+Kartal/@40.9069169,29.2030521,15z">
                              <span className="text-gray-600 hover:text-[#19876A] transition-colors">
                                Yalı Mah. Topselvi Cad. B Blok Mai Rezidans NO:
                                100B/81 Kartal İstanbul
                              </span>
                            </a>
                          </div>
                          <div className="flex items-center space-x-3">
                            <i className="fas fa-phone text-[#19876A]"></i>
                            <a
                              href="tel:+908507625909"
                              className="text-gray-600 hover:text-[#19876A] transition-colors"
                            >
                              0850 762 59 09
                            </a>
                          </div>
                          <div className="flex items-center space-x-3">
                            <i className="fas fa-envelope text-[#19876A]"></i>
                            <a
                              href="mailto:info@folyafilo.com"
                              className="text-gray-600 hover:text-[#19876A] transition-colors"
                            >
                              info@folyafilo.com
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* İletişim Formu Kartı */}
                  <Col xs={12}>
                    <div className="h-full bg-white rounded-2xl p-8 shadow-lg border border-[#19876A]/10 hover:shadow-xl transition-all duration-500">
                      <h3 className="font-serif text-xl font-semibold text-darkgray mb-6">
                        Bize Ulaşın
                      </h3>

                      <div className="space-y-6">
                        {/* İsim Alanı */}
                        <TextField
                          fullWidth
                          label="Adınız Soyadınız"
                          variant="outlined"
                          value={selectedName}
                          onChange={(e) => setSelectedName(e.target.value)}
                          InputProps={{
                            className: "rounded-lg",
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className="far fa-user text-gray-400"></i>
                              </InputAdornment>
                            ),
                          }}
                        />

                        {/* Telefon Alanı */}
                        <TextField
                          fullWidth
                          label="Telefon Numaranız"
                          variant="outlined"
                          value={selectedPhoneNumber}
                          onChange={(e) =>
                            setSelectedPhoneNumber(e.target.value)
                          }
                          InputProps={{
                            className: "rounded-lg",
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className="far fa-phone text-gray-400"></i>
                              </InputAdornment>
                            ),
                          }}
                        />

                        {/* Email Alanı */}
                        <TextField
                          fullWidth
                          label="E-posta Adresiniz"
                          variant="outlined"
                          value={selectedEmail}
                          onChange={(e) => setSelectedEmail(e.target.value)}
                          InputProps={{
                            className: "rounded-lg",
                            startAdornment: (
                              <InputAdornment position="start">
                                <i className="far fa-envelope text-gray-400"></i>
                              </InputAdornment>
                            ),
                          }}
                        />

                        {/* Gönder Butonu */}
                        <MUIButton
                          fullWidth
                          className="!bg-[#19876A] hover:!bg-[#19AA6A] !text-white !py-3 !rounded-lg !shadow-md hover:!shadow-lg !transition-all !duration-300"
                          disabled={isContactButtonDisabled}
                          onClick={handleContactRequest}
                        >
                          GÖNDER
                        </MUIButton>

                        {/* Bilgilendirme Metni */}
                        <p className="text-sm text-gray-500 text-center mt-4">
                          * Bilgileriniz gizli tutulacak ve üçüncü şahıslarla
                          paylaşılmayacaktır.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>

        {/* İletişim Dialog */}
        <Dialog
          open={contactDialogConfirmationOpen}
          onClose={handleContactConfirmationClose}
          maxWidth="sm"
          fullWidth
        >
          <div className="p-8 text-center">
            <div className="mb-6">
              <i className="fas fa-check-circle text-[#19876A] text-5xl"></i>
            </div>
            <DialogTitle className="text-xl font-serif mb-4">
              Talebiniz Alındı!
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                En kısa sürede sizinle iletişime geçeceğiz.
              </DialogContentText>
            </DialogContent>
            <DialogActions className="justify-center mt-6">
              <MUIButton
                onClick={handleContactConfirmationClose}
                className="!bg-[#19876A] hover:!bg-[#19AA6A] !text-white !px-8 !py-2 !rounded-lg"
              >
                TAMAM
              </MUIButton>
            </DialogActions>
          </div>
        </Dialog>

        {/* Footer Start */}
        <Footer theme="dark" className="bg-darkgray">
          <div className="py-20 lg:py-16 md:py-12 sm:py-10 xs:py-8">
            <Container>
              <Row className="justify-between gap-y-16">
                {/* Logo and Description */}
                <Col lg={3} md={6} sm={12} className="mb-8 lg:mb-0">
                  <Link to="/" className="inline-block mb-6">
                    <img
                      src="/assets/img/logo/logo.png"
                      alt="Folya Filo Logo"
                      className="w-32 h-auto"
                    />
                  </Link>
                  <p className="text-sm text-gray-400 font-light leading-relaxed">
                    Folya Filo ile Yatırımlarınız Yollarda Değer Kazansın!
                  </p>
                </Col>

                {/* Site Map */}
                <Col lg={3} md={6} sm={12}>
                  <h4 className="text-white text-sm font-medium mb-4 uppercase tracking-wider">
                    Site Haritası
                  </h4>
                  <ul className="space-y-2">
                    {[
                      { to: "avantajlar", text: "Avantajlar" },
                      { to: "nasil-calisir", text: "Araç Yatırımı" },
                      { to: "arac-kirala", text: "Kurumsal Araç Kiralama" },
                      {
                        to: "sikca-sorulan-sorular",
                        text: "Sıkça Sorulan Sorular",
                      },
                      { to: "hakkimizda", text: "Hakkımızda" },
                      { to: "iletisim", text: "İletişim" },
                    ].map((item) => (
                      <li key={item.to}>
                        <ScrollTo
                          to={item.to}
                          offset={80}
                          spy={true}
                          smooth={true}
                          duration={800}
                          className="text-gray-400 text-sm hover:text-white transition-colors duration-300"
                        >
                          {item.text}
                        </ScrollTo>
                      </li>
                    ))}
                    <li>
                      <Link
                        to="/gizlilik-politikasi"
                        className="text-gray-400 text-sm hover:text-white transition-colors duration-300"
                      >
                        Gizlilik Sözleşmesi
                      </Link>
                    </li>
                  </ul>
                </Col>

                {/* Contact Information */}
                <Col lg={4} md={6} sm={12}>
                  <h4 className="text-white text-sm font-medium mb-4 uppercase tracking-wider">
                    İletişim
                  </h4>
                  <ul className="space-y-3">
                    <li className="flex items-start space-x-3">
                      <i className="fas fa-map-marker-alt text-[#19876A] mt-1 text-sm"></i>
                      <a
                        href="https://www.google.com.tr/maps/dir/40.9141248,29.2159488/Folya+Filo+Kiralama+A.%C5%9E+Kartal/@40.9069169,29.2030521,15z"
                        className="text-gray-400 text-sm hover:text-white transition-colors duration-300"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Yalı Mah. Topselvi Cad. B Blok Mai Rezidans NO: 100B/81
                        Kartal İstanbul
                      </a>
                    </li>
                    <li className="flex items-center space-x-3">
                      <i className="fas fa-phone text-[#19876A] text-sm"></i>
                      <a
                        href="tel:+908507625909"
                        className="text-gray-400 text-sm hover:text-white transition-colors duration-300"
                      >
                        0850 762 59 09
                      </a>
                    </li>
                    <li className="flex items-center space-x-3">
                      <i className="fas fa-envelope text-[#19876A] text-sm"></i>
                      <a
                        href="mailto:info@folyafilo.com"
                        className="text-gray-400 text-sm hover:text-white transition-colors duration-300"
                      >
                        info@folyafilo.com
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>

          {/* Copyright Section */}
          <div className="border-t border-gray-800">
            <Container>
              <div className="py-4">
                <p className="text-center text-gray-400 text-sm">
                  &copy; {new Date().getFullYear()} Folya Filo. Tüm Hakları
                  Saklıdır.
                </p>
              </div>
            </Container>
          </div>
        </Footer>
        {/* Footer End */}

        {/* Section End */}
      </InViewPort>

      <Dialog open={contactDialogOpen} onClose={handleContactDialogClose}>
        <Container className="flex flex-col w-fit bg-white p-4 rounded mt-4 md:!w-[70%] sm:!w-[85%] xs:!w-full">
          <Row className="justify-center">
            <Col className="text-center">
              <DialogTitle className="text-large">
                Bir İstek Bırakın
              </DialogTitle>
              <DialogContentText>
                Sorularınızı yanıtlamak için sizi 5 dakika içinde geri
                arayacağız.
              </DialogContentText>
            </Col>
          </Row>
          <DialogContent>
            <TextField
              margin="dense"
              id="name"
              label="Adınız"
              fullWidth
              onChange={(e) => setSelectedName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="phone"
              label="Telefon Numaranız"
              type="phone"
              fullWidth
              onChange={(e) => setSelectedPhoneNumber(e.target.value)}
            />
            <TextField
              margin="dense"
              id="email"
              label="Email Adresiniz"
              type="email"
              fullWidth
              onChange={(e) => setSelectedEmail(e.target.value)}
            />
            <p className="text-center mt-2">
              Düğmeye tıklayarak gizlilik sözleşmesini kabul etmiş olursunuz.
            </p>
          </DialogContent>
          <DialogActions>
            <MUIButton
              disabled={isContactButtonDisabled}
              className="!bg-green-800 text-white mx-[10px] font-large font-serif uppercase rounded !min-w-[200px] md:!mb-12 md:!w-full"
              size="lg"
              onClick={handleContactRequest}
            >
              BİR İSTEK BIRAKIN
            </MUIButton>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
};

export default HomeStartupPage;
